export const APP_STATUS = "production";
// local
export const LOCAL_BACKEND_URL = "http://localhost:8080";
export const LOCAL_API_URL = "http://localhost:8080/api";
export const LOCAL_DOMAIN_URL = "http://localhost:3000";

// production
export const PRODUCTION_BACKEND_URL = "https://pbe.aga-tax.com";
export const PRODUCTION_API_URL = "https://pbe.aga-tax.com/api";
export const PRODUCTION_DOMAIN_URL = "https://portal.aga-tax.com";

// others
export const cloudName = "dxxortq4j";
export const uploadPreset = "agaimages";
