import React, { createContext, useState } from "react";
import {
  DOWNLOAD_ATTACHMENT_API_URL,
  DOWNLOAD_MULTIPLE_FILES_API_URL,
} from "../config/endPoints";
import axios from "axios";
import { message } from "antd";

const UtilsCtx = createContext();

const UtilsContext = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleDownloadAttachment = async (fid, fn, fp) => {
    try {
      const filepath = fp.replace(/^src\/data\//, "");

      const url = `${DOWNLOAD_ATTACHMENT_API_URL}/${fid}/download/${filepath}`;

      const res = await axios.get(url, { responseType: "blob" });

      if (res.status !== 200) {
        message.error(`Error downloading file: ${res.status}`);
        return;
      }

      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      const link = document.createElement("a");
      link.download = fn;
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      message.error(err?.response?.data?.message);
      console.log(err);
    }
  };

  const downloadMultipleFiles = async (folderId) => {
    try {
      const url = `${DOWNLOAD_MULTIPLE_FILES_API_URL}/${folderId}`;
      const res = await axios.get(url);

      if (res.data.message === "No files found in the folder.") {
        message.error("No files found in the folder.");
        return;
      }

      const fileData = res.data.files;

      const downloadFileSequentially = async (files, index = 0) => {
        if (index < files.length) {
          const file = files[index];
          const { _id: fileId, filename } = file;
          const downloadUrl = `${DOWNLOAD_ATTACHMENT_API_URL}/${fileId}/download/attachments/${filename}`;

          try {
            const response = await axios.get(downloadUrl, {
              responseType: "blob",
            });
            const blob = response.data;
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            await downloadFileSequentially(files, index + 1);
          } catch (error) {
            console.error("Error downloading file:", error);
            message.error("Error downloading file");
          }
        } else {
          message.success("Files downloaded successfully");
        }
      };

      await downloadFileSequentially(fileData);
    } catch (error) {
      console.error("Error:", error);
      message.error("Something went wrong");
    }
  };

  return (
    <UtilsCtx.Provider
      value={{
        handleDownloadAttachment,
        downloadMultipleFiles,
        isSidebarOpen,
        setIsSidebarOpen,
      }}
    >
      {children}
    </UtilsCtx.Provider>
  );
};

export { UtilsContext, UtilsCtx };
