import { message } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FORGET_PASSWORD_API_URL } from "../config/endPoints";
import axios from "axios";
import { Helmet } from "react-helmet";

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email)
      return message.error(
        "Email required to reset password. Please enter email."
      );
    setLoading(true);
    axios
      .post(FORGET_PASSWORD_API_URL, { email })
      .then((res) => {
        setLoading(false);
        message.success(
          "Password reset email sent. Check your inbox and spam folder!"
        );
        setEmail("");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password - AGA Tax Client Portal</title>
        <meta name="" content="" />
      </Helmet>

      <link rel="stylesheet" href="./css/main.css" />
      {/*====================== Start Sign in Form ======================*/}
      <section className="sign-in py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-7 col-xl-5">
              <div className="form-wrapper py-lg-3">
                <div className="logo-wrapper text-center mb-4 mb-lg-5 pb-lg-2">
                  <Link to="/">
                    <img
                      src="assets/images/aga-tax-logo.png"
                      className="img-fluid"
                      width={220}
                      alt
                    />
                  </Link>
                </div>
                <form action="#" className="sign-in-form">
                  <div className="form-title text-center mb-md-5 mb-4">
                    <h3 className="display-6 fw-semibold mb-3">
                      Forgot Password?
                    </h3>
                    <p className="text-muted">
                      Enter your email to get a password reset link
                    </p>
                  </div>
                  <div className="mb-3">
                    <input
                      id="email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control bg-white border-0 px-3"
                      placeholder="Email Address"
                      autofocus
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                    className="btn primary-btn shadow w-100 mb-2"
                  >
                    {loading && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}
                    {!loading ? "Reset Password" : "Sending Email..."}
                  </button>
                  <p className="mt-4 text-center text-muted">
                    Remember your password?{" "}
                    <Link
                      to="/"
                      className="text-decoration-none text-decoration-underline create-account-action"
                    >
                      Login
                    </Link>
                  </p>
                </form>
              </div>
              {/*.form-wrapper*/}
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.container*/}
      </section>
      {/*====================== End Sign in Form ======================*/}
    </>
  );
}

export default ForgotPassword;
