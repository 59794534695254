import { BACKEND_URL } from "../config/endPoints";

export function validateEmail(email) {
  const re = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(password) {
  return password.length >= 8;
}

export const constructImageUrl = (imageUrl) => {
  if (!imageUrl) {
    return null;
  }

  if (imageUrl.startsWith("src")) {
    return `${BACKEND_URL}/${imageUrl}`;
  } else if (imageUrl.startsWith("https://res.cloudinary.com")) {
    return imageUrl;
  } else {
    return null;
  }
};

export function truncateString(inputString, maxWords) {
  const words = inputString?.split(" ");

  if (words?.length > maxWords) {
    const truncatedString = words?.slice(0, maxWords).join(" ");

    return truncatedString + "...";
  } else {
    return inputString;
  }
}

export function getFileIcon(url) {
  const lastDotIndex = url.lastIndexOf(".");
  if (lastDotIndex !== -1 && lastDotIndex < url.length - 1) {
    const fileExtension = url.slice(lastDotIndex + 1).toLowerCase();
    switch (fileExtension) {
      case "jpg":
        return "bi bi-filetype-jpg";
      case "jpeg":
      case "webp":
      case "heif":
        return "bi bi-file-earmark-image";
      case "png":
        return "bi bi-filetype-png";
      case "heic":
        return "bi bi-filetype-heic";
      case "gif":
        return "bi bi-filetype-gif";
      case "tiff":
        return "bi bi-filetype-tiff";
      case "bmp":
        return "bi bi-filetype-bmp";
      case "svg":
        return "bi bi-filetype-svg";
      case "pdf":
        return "bi bi-file-earmark-pdf-fill";
      case "docx":
        return "bi bi-filetype-docx";
      case "doc":
        return "bi bi-filetype-doc";
      case "pptx":
        return "bi bi-filetype-pptx";
      case "ppt":
        return "bi bi-filetype-ppt";
      case "xlsx":
        return "bi bi-filetype-xlsx";
      case "xls":
        return "bi bi-filetype-xls";
      case "txt":
        return "bi bi-filetype-txt";
      case "csv":
        return "bi bi-filetype-csv";
      case "html":
        return "bi bi-filetype-html";
      case "css":
        return "bi bi-filetype-css";
      case "scss":
        return "bi bi-filetype-scss";
      case "js":
        return "bi bi-filetype-js";
      case "jsx":
        return "bi bi-filetype-jsx";
      case "json":
        return "bi bi-filetype-json";
      case "xml":
        return "bi bi-filetype-xml";
      case "zip":
        return "bi bi-file-zip";
      case "mp3":
        return "bi bi-filetype-mp3";
      case "wav":
        return "bi bi-filetype-wav";
      case "aac":
        return "bi bi-filetype-aac";
      case "mp4":
        return "bi bi-filetype-mp4";
      case "mov":
        return "bi bi-filetype-mov";
      default:
        return "bi bi-file-check";
    }
  } else {
    return "bi bi-file-check-fill";
  }
}

export function truncateFileName(fileName, maxLength) {
  if (fileName?.length <= maxLength) {
    return fileName;
  }

  const extensionIndex = fileName?.lastIndexOf(".");
  const nameWithoutExtension = fileName?.substring(0, extensionIndex);
  const truncatedName = nameWithoutExtension?.substring(0, maxLength - 3);
  const extension = fileName?.substring(extensionIndex);

  return truncatedName + ".." + extension;
}

export const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) {
    return "";
  }

  const options = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const dateTime = new Date(dateTimeString);
  const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
    dateTime
  );

  return formattedDateTime;
};
