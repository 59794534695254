import React, { useContext, useEffect, useState } from "react";
import AdminSidebar from "../../components/layout/admin/AdminSidebar";
import AdminHeader from "../../components/layout/admin/AdminHeader";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth";
import { FileCtx } from "../../context/File";
import setAuthToken from "../../utils/setAuthToken";
import Select from "react-select";
import {
  constructImageUrl,
  formatDateTime,
  getFileIcon,
  truncateFileName,
  truncateString,
} from "../../utils/helpers";
import {
  BACKEND_URL,
  CLIENT_ACCESS_API_URL,
  CONFIRM_LOCK_API_URL,
  DELETE_FILE_API_URL,
  RENAME_FOLDER_API_URL,
} from "../../config/endPoints";
import axios from "axios";
import { Popconfirm, message } from "antd";
import { UtilsCtx } from "../../context/UtilsContext";
import { Helmet } from "react-helmet";

function AdminDashboard() {
  const token = localStorage.getItem("token");

  const { userInfo, getAdminUsers, adminUsers } = useContext(AuthContext);
  const { getAllFiles, allFiles, setSFileId, setPName, setPStatus, setPId } =
    useContext(FileCtx);
  const { handleDownloadAttachment, isSidebarOpen, downloadMultipleFiles } =
    useContext(UtilsCtx);

  const [selectedOption, setSelectedOption] = useState(null);
  const [newFolderName, setNewFolderName] = useState("");
  const [fId, setFId] = useState("");
  const [isLocked, setIsLocked] = useState({
    id: "",
    status: true,
    folderName: "",
  });
  const [cAccess, setCAccess] = useState({ clientAccess: [] });
  const [folderId, setFolderId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedFInfo, setSelectedFInfo] = useState({});

  const handleSetFolder = (i, f) => {
    setFolderId(i);
    setSelectedFInfo(f);
  };

  const handleLockFolder = (i, s, n) => {
    setIsLocked({
      id: i,
      status: s,
      folderName: n,
    });
  };

  const sFileIdHandler = (oid, n, ps, pi) => {
    setSFileId(oid);
    localStorage.setItem("sFileId", oid);
    setPName(n);
    localStorage.setItem("pName", n);
    setPStatus(ps);
    localStorage.setItem("pStatus", ps);
    setPId(pi);
    localStorage.setItem("pId", pi);
  };

  const navigate = useNavigate();

  useEffect(() => {
    document.body.className = "admin-portal";

    return () => {
      document.body.className = "";
    };
  }, []);

  if (userInfo?.userType === "client") {
    navigate("/dashboard");
  }

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      getAllFiles(token);
      getAdminUsers(token);
    }
  }, []);

  const confirm = (id, ft) => {
    axios
      .delete(`${DELETE_FILE_API_URL}/${id}`)
      .then(() => {
        getAllFiles(token);
        message.success(
          `${ft === "folder" ? "Folder" : "File"} deleted successfully.`
        );
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const confirmFFDownload = (id, ft) => {
    axios
      .delete(`/${id}`)
      .then(() => {
        getAllFiles(token);
        message.success(
          `${ft === "folder" ? "Folder" : "File"} deleted successfully.`
        );
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const confirmLock = () => {
    const newStatus = !isLocked.status;

    axios
      .put(`${CONFIRM_LOCK_API_URL}/${isLocked.id}`, { status: newStatus })
      .then(() => {
        getAllFiles(token);
        message.success(
          `Folder ${isLocked.status ? "unlocked" : "locked"} successfully`
        );
        setTimeout(() => {
          const cancelButton = document.querySelector("#lockModal .gray-btn");
          if (cancelButton) {
            cancelButton.click();
          }
        }, 500);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const handleNameNIdChange = (i, n) => {
    setFId(i);
    setNewFolderName(n);
  };

  const renameFolder = () => {
    if (!newFolderName.trim()) {
      message.error("Please write a folder name");
      return;
    }

    axios
      .put(`${RENAME_FOLDER_API_URL}/${fId}`, { newFolderName })
      .then(() => {
        getAllFiles(token);
        message.success("Folder renamed successfully");
        setTimeout(() => {
          const cancelButton = document.querySelector(
            "#staticBackdrop .gray-btn"
          );
          if (cancelButton) {
            cancelButton.click();
          }
        }, 500);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const handleUserChange = (selected) => {
    setSelectedUsers(selected);
  };

  useEffect(() => {
    const newOptions = adminUsers?.map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.lastName}`,
      image: user?.imageUrl,
      email: user?.email,
    }));
    setUserOptions(newOptions);
  }, [adminUsers]);

  useEffect(() => {
    if (
      selectedFInfo?.clientAccess &&
      selectedFInfo?.clientAccess?.length > 0
    ) {
      const teamMembers = selectedFInfo?.clientAccess?.map((tm) => ({
        value: tm?._id,
        label: `${tm?.firstName} ${tm?.lastName}`,
        image: tm?.imageUrl,
        email: tm?.email,
      }));
      if (teamMembers.length > 0) {
        setSelectedUsers(teamMembers);
      }
    } else {
      setSelectedUsers([]);
    }
  }, [selectedFInfo]);

  useEffect(() => {
    const selectedUserIds = selectedUsers?.map((tm) => tm?.value || null);

    setCAccess((prevState) => ({
      ...prevState,
      clientAccess: selectedUserIds,
    }));
  }, [selectedUsers]);

  const updateClientAccess = () => {
    axios
      .put(`${CLIENT_ACCESS_API_URL}/${folderId}`, cAccess)
      .then((res) => {
        message.success(res.data.message);
        getAllFiles(token);
        setTimeout(() => {
          const cancelButton = document.querySelector("#shareModal .gray-btn");
          if (cancelButton) {
            cancelButton.click();
          }
          setCAccess({ clientAccess: [] });
        }, 500);
      })
      .catch((err) => {
        message.error(err.response.data.error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Admin Dashboard - AGA Tax Client Portal</title>
        <meta name="" content="" />
      </Helmet>

      <link rel="stylesheet" href="./css/main.css" />
      <body className="admin-portal">
        {/*=============== Start Dashboard  ===============*/}
        <section
          className={
            isSidebarOpen
              ? "dashboard-wrapper"
              : "dashboard-wrapper sidebar-toggle"
          }
        >
          <AdminSidebar />
          {/*.aside*/}
          <AdminHeader />
          {/*.header*/}
          <main className="page-content shadow-sm d-flex flex-column justify-content-between">
            <div className="container-fluid p-3 p-md-4">
              <div className="row">
                <div className="col-12">
                  <div className="page-header d-flex flex-column flex-sm-row align-items-md-center justify-content-between gap-3 mb-4">
                    <div className="breadcrumb-wrapper">
                      <h3 className="fs-4">
                        {allFiles?.length === 0
                          ? "Your Dashboard Is Currently Empty."
                          : "Dashboard: Recent Folders and Files"}
                      </h3>
                    </div>
                    {/*breadcrumb-wrapper*/}
                    <div className="page-header-right">
                      <div className="d-inline-flex rounded-pill overflow-hidden">
                        <input
                          type="radio"
                          className="btn-check"
                          name="options"
                          id="option1"
                          autoComplete="off"
                          checked={selectedOption === "Files"}
                          onChange={() => setSelectedOption("Files")}
                        />
                        <label
                          className="btn btn-light rounded-0"
                          htmlFor="option1"
                        >
                          <i className="bi bi-card-text" /> Files
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="options"
                          id="option2"
                          autoComplete="off"
                          checked={selectedOption === "Folders"}
                          onChange={() => setSelectedOption("Folders")}
                        />
                        <label
                          className="btn btn-light rounded-0"
                          htmlFor="option2"
                        >
                          <i className="bi bi-folder" /> Folders
                        </label>
                      </div>
                    </div>
                  </div>
                  {/*.page-header*/}
                  <div className="page-body position-relative mb-4">
                    <div className="row g-3">
                      {allFiles
                        ?.filter(
                          (f) =>
                            selectedOption === null ||
                            (selectedOption === "Files"
                              ? f.type === "file"
                              : f.type === "folder")
                        )
                        .slice(0, 18)
                        .map((f, i) => (
                          <div className="col-md-4 col-sm-6" key={i}>
                            <div className="listing-item">
                              <div className="icon-wrapper">
                                <span className="icon">
                                  <i
                                    className={
                                      f?.type === "folder"
                                        ? "bi bi-folder-fill"
                                        : getFileIcon(
                                            `${BACKEND_URL}/${f?.filePath}`
                                          )
                                    }
                                  />
                                </span>
                              </div>
                              <div className="item-text">
                                <Link
                                  to={
                                    f?.type === "file"
                                      ? ""
                                      : "/admin-inner-folder"
                                  }
                                  style={{
                                    textDecoration: "none",
                                    color: "#212529",
                                  }}
                                  onClick={() =>
                                    sFileIdHandler(
                                      f._id,
                                      f?.name,
                                      f?.isLocked,
                                      f?.parent
                                    )
                                  }
                                >
                                  {f?.type === "file"
                                    ? truncateFileName(f?.filename, 27)
                                    : truncateString(f?.name, 5)}
                                  {f?.type === "file"
                                    ? ` - ${formatDateTime(f?.createdAt)}`
                                    : ""}
                                </Link>
                              </div>
                              <div className="item-action dropdown">
                                <a
                                  href="#"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="bi bi-three-dots-vertical" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end border py-0 shadow-sm">
                                  {f?.type === "file" && (
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() =>
                                          handleDownloadAttachment(
                                            f?._id,
                                            f?.filename,
                                            f?.filePath
                                          )
                                        }
                                      >
                                        <i className="bi bi-download" />{" "}
                                        Download
                                      </a>
                                    </li>
                                  )}
                                  {f?.type === "folder" && (
                                    <>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdrop"
                                          onClick={() =>
                                            handleNameNIdChange(f?._id, f?.name)
                                          }
                                        >
                                          <i className="bi bi-pencil" /> Rename
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#shareModal"
                                          onClick={() =>
                                            handleSetFolder(f._id, f)
                                          }
                                        >
                                          <i className="bi bi-person-add" />{" "}
                                          Share
                                        </a>
                                      </li>
                                      {f?.isLocked ? (
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#lockModal"
                                            onClick={() =>
                                              handleLockFolder(
                                                f?._id,
                                                f?.isLocked,
                                                f?.name
                                              )
                                            }
                                          >
                                            <i className="bi bi-unlock" />
                                            Unlock
                                          </a>
                                        </li>
                                      ) : (
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#lockModal"
                                            onClick={() =>
                                              handleLockFolder(
                                                f?._id,
                                                f?.isLocked,
                                                f?.name
                                              )
                                            }
                                          >
                                            <i className="bi bi-lock" />
                                            Lock
                                          </a>
                                        </li>
                                      )}
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            downloadMultipleFiles(f?._id)
                                          }
                                        >
                                          <i className="bi bi-download" />{" "}
                                          Download Files
                                        </a>
                                      </li>
                                    </>
                                  )}

                                  <li>
                                    <Popconfirm
                                      title="Confirm Delete"
                                      description={`Are you sure you want to delete this ${f?.type}?`}
                                      onConfirm={() => confirm(f?._id, f?.type)}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        id="deleteToastBtn"
                                      >
                                        <i className="bi bi-trash" /> Delete
                                      </a>
                                    </Popconfirm>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {/*.listing-item*/}
                          </div>
                        ))}
                    </div>
                    {/*..row*/}
                    {/* <div className="row mt-5">
                      <div className="col-12 text-center">
                        <a href="#" className="btn primary-btn">
                          Show more
                        </a>
                      </div>
                    </div> */}
                  </div>
                  {/*.page-body*/}
                </div>
                {/*.col-grid*/}
              </div>
              {/*.row*/}
            </div>
            {/*.container-fluid*/}
          </main>
          {/*.page-content"*/}
        </section>
        {/* Modal Rename */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Rename
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control"
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                />
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn gray-btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn px-4"
                  onClick={renameFolder}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Share */}
        <div
          className="modal fade"
          id="shareModal"
          tabIndex={-1}
          aria-labelledby="shareModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className="modal-title fs-5" id="shareModalLabel">
                  Share With Clients
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <Select
                  isMulti
                  placeholder="Add client ..."
                  options={userOptions}
                  value={selectedUsers}
                  onChange={handleUserChange}
                />
                <div className="mt-4">
                  <h6 className="mb-3">Clients with access</h6>
                  <ul className="list-unstyled p-access-list d-flex flex-column gap-2">
                    {selectedUsers?.map((user) => (
                      <li
                        key={user.value}
                        className="d-flex align-items-center justify-content-between gap-2"
                      >
                        <div className="list-left d-flex align-items-center gap-2">
                          <div className="img-wrapper d-block rounded-pill overflow-hidden me-sm-1">
                            {/* Assuming you have an image URL in the user object */}
                            {user?.image ? (
                              <img
                                className="img-fluid"
                                src={constructImageUrl(user?.image)}
                                alt={user?.label}
                              />
                            ) : (
                              <img
                                className="img-fluid"
                                src="./assets/images/client.png"
                                alt={user?.label}
                              />
                            )}
                          </div>
                          <div className="list-text">
                            <h6 className="fw-semibold mb-0">{user?.label}</h6>
                            <span className="text-secondarys small">
                              {user?.email}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn gray-btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn px-4"
                  onClick={updateClientAccess}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Lock File */}
        <div
          className="modal fade"
          id="lockModal"
          tabIndex={-1}
          aria-labelledby="lockModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1
                  className="modal-title fs-5 d-inline-flex align-items-center gap-2"
                  id="lockModalLabel"
                >
                  <span>
                    <i
                      className={`bi bi-${isLocked.status ? "unlock" : "lock"}`}
                    />
                  </span>
                  Confirm {isLocked.status ? "Unlock" : "Lock"}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to {isLocked.status ? "unlock" : "lock"}{" "}
                  this folder?
                </p>
                <div className="listing-item d-inline-flex bg-light shadow-none">
                  <div className="icon-wrapper">
                    <span className="icon">
                      <i className="bi bi-folder-fill" />
                    </span>
                  </div>
                  <div className="item-text">
                    <span>{isLocked?.folderName}</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn gray-btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn px-4"
                  onClick={confirmLock}
                >
                  {isLocked.status ? "Unlock" : "Lock"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          <div
            id="liveToast"
            className="toast shadow-sm p-1"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header border-0 d-flex align-items-center gap-2">
              <span className="icon">
                <i className="bi bi-trash3-fill" />
              </span>
              <strong className="me-auto fw-semibold">
                Develompent folder deleted
              </strong>
            </div>
          </div>
        </div>
      </body>

      {/*delete toast*/}
      {/*=============== End Dashboard ===============*/}
    </>
  );
}

export default AdminDashboard;
