import React, { createContext, useState } from "react";
import {
  CREATE_FOLDER_API_URL,
  GET_ALL_FILES_API_URL,
  GET_FILES_API_URL,
  GET_FOLDERS_API_URL,
  GET_INNER_FILES_API_URL,
  GET_TOP_PARENT_FOLDER_API_URL,
} from "../config/endPoints";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const FileCtx = createContext();

const FileContext = ({ children }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [folderName, setFolderName] = useState("Untitled folder");
  const [allFiles, setAllFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [topParentFolder, setTopParentFolder] = useState([]);
  const [innerFiles, setInnerFiles] = useState([]);
  const [sFileId, setSFileId] = useState(localStorage.getItem("sFileId") || "");
  const [pName, setPName] = useState(localStorage.getItem("pName") || "");
  const [pStatus, setPStatus] = useState(localStorage.getItem("pStatus") || "");
  const [pId, setPId] = useState(localStorage.getItem("pId") || "");

  const createFolder = () => {
    const formData = {
      name: folderName,
    };
    axios
      .post(CREATE_FOLDER_API_URL, formData)
      .then((res) => {
        message.success(res.data.message);
        getFolders(token);
        setTimeout(() => {
          const cancelButton = document.querySelector(
            "#createFolderModal .gray-btn"
          );
          if (cancelButton) {
            cancelButton.click();
          }
        }, 500);
        setFolderName("Untitled folder");
      })
      .catch((err) => {
        message.error(err.response.data.error);
      });
  };

  const createInnerFolder = () => {
    const formData = {
      name: folderName,
      parentId: sFileId,
    };
    axios
      .post(CREATE_FOLDER_API_URL, formData)
      .then((res) => {
        message.success(res.data.message);
        getInnerFiles(token);
        setTimeout(() => {
          const cancelButton = document.querySelector(
            "#createFolderModal .gray-btn"
          );
          if (cancelButton) {
            cancelButton.click();
          }
        }, 500);
        setFolderName("Untitled folder");
      })
      .catch((err) => {
        message.error(err.response.data.error);
      });
  };

  const getAllFiles = async (token) => {
    if (token) {
      try {
        const res = await axios.get(`${GET_ALL_FILES_API_URL}`);

        setAllFiles(res.data);
      } catch (err) {
        message.error(err?.response?.data?.error);
      }
    }
  };

  const getInnerFiles = async (token) => {
    if (token) {
      if (!sFileId) {
        message.error("Please select folder again!");
        navigate("/admin-drive");
        return;
      }
      try {
        const res = await axios.get(`${GET_INNER_FILES_API_URL}/${sFileId}`);

        setInnerFiles(res.data);
      } catch (err) {
        message.error(err?.response?.data?.error);
      }
    }
  };

  const getFiles = async (token) => {
    if (token) {
      try {
        const res = await axios.get(`${GET_FILES_API_URL}`);

        setFiles(res.data);
      } catch (err) {
        message.error(err?.response?.data?.error);
      }
    }
  };

  const getFolders = async (token) => {
    if (token) {
      try {
        const res = await axios.get(`${GET_FOLDERS_API_URL}`);

        setFolders(res.data);
      } catch (err) {
        message.error(err?.response?.data?.error);
      }
    }
  };

  const getTopParentFolder = async (token) => {
    if (token) {
      try {
        const res = await axios.get(`${GET_TOP_PARENT_FOLDER_API_URL}`);

        setTopParentFolder(res.data);
      } catch (err) {
        message.error(err?.response?.data?.error);
      }
    }
  };

  return (
    <FileCtx.Provider
      value={{
        folderName,
        setFolderName,
        createFolder,
        getAllFiles,
        allFiles,
        getFolders,
        folders,
        files,
        getFiles,
        setSFileId,
        sFileId,
        innerFiles,
        getInnerFiles,
        pName,
        setPName,
        createInnerFolder,
        pStatus,
        setPStatus,
        pId,
        setPId,
        getTopParentFolder,
        topParentFolder,
      }}
    >
      {children}
    </FileCtx.Provider>
  );
};

export { FileContext, FileCtx };
