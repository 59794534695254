import React, { useContext } from "react";
import ClientSidebar from "../../components/layout/client/ClientSidebar";
import ClientHeader from "../../components/layout/client/ClientHeader";
import ClientProfile from "../../components/layout/client/profile/ClientProfile";
import { UtilsCtx } from "../../context/UtilsContext";
import { Helmet } from "react-helmet";

function Profile() {
  const { isSidebarOpen } = useContext(UtilsCtx);

  return (
    <>
      <Helmet>
        <title>Profile - AGA Tax Client Portal</title>
        <meta name="" content="" />
    </Helmet>

      <link rel="stylesheet" href="./css/main.css" />
      {/*=============== Start Dashboard  ===============*/}
      <section
        className={
          isSidebarOpen
            ? "dashboard-wrapper"
            : "dashboard-wrapper sidebar-toggle"
        }
      >
        <ClientSidebar />
        {/*.aside*/}
        <ClientHeader />
        {/*.header*/}
        <ClientProfile />
        {/*.page-content"*/}
      </section>
      {/*=============== End Dashboard ===============*/}
    </>
  );
}

export default Profile;
