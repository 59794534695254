import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Home() {
  const { login, loading, userInfo } = useContext(AuthContext);

  const token = localStorage.getItem("token");

  const [data, setData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setData({ ...data, [e.target.name]: e.target.checked });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    login(data);
  };

  if (token && !loading && userInfo?.userType) {
    return (
      <Navigate
        to={userInfo?.userType === "admin" ? "/admin-dashboard" : "/dashboard"}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Login - AGA Tax Client Portal</title>
        <meta name="" content="" />
      </Helmet>
      <link rel="stylesheet" href="./css/main.css" />
      {/*====================== Start Sign in Form ======================*/}
      <section className="sign-in py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-7 col-xl-5">
              <div className="form-wrapper py-lg-3">
                <div className="logo-wrapper text-center mb-4 mb-lg-5 pb-lg-2">
                  <Link to="/">
                    <img
                      src="assets/images/aga-tax-logo.png"
                      className="img-fluid"
                      width={220}
                      alt
                    />
                  </Link>
                </div>
                <form action="#" className="sign-in-form">
                  <div className="form-title text-center mb-md-5 mb-4">
                    <h3 className="display-6 fw-semibold mb-3">
                      Login to your account
                    </h3>
                    <p className="text-muted">
                      Welcome back! Please enter your details.
                    </p>
                  </div>
                  <div className="mb-3">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      className="form-control bg-white"
                      placeholder="Email address"
                      value={data.email}
                      onChange={handleChange}
                      autofocus
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      name="password"
                      id="password"
                      type="password"
                      className="form-control bg-white"
                      placeholder="Password"
                      value={data.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember"
                        name="rememberMe"
                        onChange={handleChange}
                        checked={data.rememberMe}
                      />
                      <label className="form-check-label" htmlFor="remember">
                        <small>Remember Me</small>
                      </label>
                    </div>
                    <Link
                      to="/forgot-password"
                      className="text-decoration-none"
                    >
                      <small>Forgot Your Password?</small>
                    </Link>
                  </div>
                  <Link
                    to="/dashboard"
                    type="submit"
                    className="btn primary-btn shadow w-100 mb-2"
                    disabled={loading}
                    onClick={handleLogin}
                  >
                    {loading && (
                      <span className="spinner-grow spinner-grow-sm me-2"></span>
                    )}
                    {!loading ? "Log In" : "Logging In..."}
                  </Link>
                </form>
              </div>
              {/*.form-wrapper*/}
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.container*/}
      </section>
      {/*====================== End Sign in Form ======================*/}
    </>
  );
}

export default Home;
