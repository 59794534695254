import React, { createContext, useState } from "react";

const SearchCtx = createContext();

const SearchContext = ({ children }) => {
  const [searchOrder, setSearchOrder] = useState("");

  return (
    <SearchCtx.Provider
      value={{
        searchOrder,
        setSearchOrder,
      }}
    >
      {children}
    </SearchCtx.Provider>
  );
};

export { SearchContext, SearchCtx };
