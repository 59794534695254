import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/client/Dashboard";
import MyDrive from "./pages/client/MyDrive";
import InnerFolder from "./pages/client/InnerFolder";
import Profile from "./pages/client/Profile";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminDrive from "./pages/admin/AdminDrive";
import ManageUsers from "./pages/admin/ManageUsers";
import Admin from "./pages/admin/Admin";
import { useContext, useEffect } from "react";
import setAuthToken from "./utils/setAuthToken";
import { AuthContext } from "./context/Auth";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import PageNotFound from "./pages/404";
import AdminInnerFolder from "./pages/admin/InnerFolder";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

function App() {
  //   useEffect(() => {
  //     const script = document.createElement("script");
  //     script.innerHTML = `
  //     const toastTrigger = document.getElementById('deleteToastBtn')
  // const toastLiveExample = document.getElementById('liveToast')
  // if (toastTrigger) {
  //   toastTrigger.addEventListener('click', () => {
  //     const toast = new bootstrap.Toast(toastLiveExample)

  //     toast.show()
  //   })
  // }
  //     `;
  //     document.body.appendChild(script);
  //   }, []);

  const token = localStorage.getItem("token");

  const { fetchUserInfo } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      fetchUserInfo(token);
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      <Route element={<ProtectedRoutes />}>
        {/* Client Routes */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/my-drive" element={<MyDrive />} />
        <Route path="/inner-folder" element={<InnerFolder />} />
        <Route path="/profile" element={<Profile />} />

        {/* Admin Routes */}
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/admin-drive" element={<AdminDrive />} />
        <Route path="/admin-inner-folder" element={<AdminInnerFolder />} />
        <Route path="/manage-clients" element={<ManageUsers />} />
        <Route path="/admin" element={<Admin />} />
      </Route>
    </Routes>
  );
}

export default App;
