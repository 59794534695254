import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import PageNotFound from "./404";
import axios from "axios";
import { VERFIY_LINK_TOKEN_API_URL } from "../config/endPoints";
import UpdatePassword from "../components/layout/client/mix/UpdatePassword";
import { Helmet } from "react-helmet";

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const link_token = searchParams.get("token");

  const [access, setAccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const t = (to) => {
    setLoading(true);
    axios
      .post(VERFIY_LINK_TOKEN_API_URL, {
        token: to,
      })
      .then((res) => {
        setAccess(res.data.link_alive);
        setLoading(false);
      })
      .catch((err) => {
        setAccess(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (link_token) {
      t(link_token);
    }
  }, [link_token]);

  if (!access && !loading) return <PageNotFound />;

  return (
    <>
      <Helmet>
        <title>Login - AGA Tax Client Portal</title>
        <meta name="" content="" />
      </Helmet>

      <link rel="stylesheet" href="./css/main.css" />
      {/*====================== Start Sign in Form ======================*/}
      <section className="sign-in py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-7 col-xl-5">
              <div className="form-wrapper py-lg-3">
                <div className="logo-wrapper text-center mb-4 mb-lg-5 pb-lg-2">
                  <Link to="/">
                    <img
                      src="assets/images/aga-tax-logo.png"
                      className="img-fluid"
                      width={220}
                      alt
                    />
                  </Link>
                </div>
                <UpdatePassword token={access && link_token} />
              </div>
              {/*.form-wrapper*/}
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.container*/}
      </section>
      {/*====================== End Sign in Form ======================*/}
    </>
  );
}

export default ResetPassword;
