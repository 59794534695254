import React from "react";
import { Link, NavLink } from "react-router-dom";

function AdminSidebar() {
  return (
    <>
      <aside className="position-fixed top-0 start-0">
        <div className="sidebar-wrapper h-100 d-flex flex-column">
          <div className="logo-wrapper px-3">
            <Link to="/">
              <img
                src="./assets/images/aga-white-logo.png"
                alt
                className="img-fluid"
              />
            </Link>
          </div>
          <div className="sidebar-menu-wrapper py-4 my-2">
            <ul
              className="sidebar-listing list-unstyled px-3 mb-0 pb-4"
              id="collapse"
            >
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center mb-1"
                      : "nav-link d-flex align-items-center mb-1"
                  }
                  to="/admin-dashboard"
                >
                  <span className="icon d-inline-flex">
                    <i className="bi bi-house" />
                    <i className="bi bi-house-fill" />
                  </span>
                  <span className="sidebar-item">Dashboard</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center mb-1"
                      : "nav-link d-flex align-items-center mb-1"
                  }
                  to="/admin-drive"
                >
                  <span className="icon d-inline-flex">
                    <i className="bi bi-hdd-stack" />
                    <i className="bi bi-hdd-stack-fill" />
                  </span>
                  <span className="sidebar-item">My Drive</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center mb-1"
                      : "nav-link d-flex align-items-center mb-1"
                  }
                  to="/manage-clients"
                >
                  <span className="icon d-inline-flex">
                    <i className="bi bi-people" />
                    <i className="bi bi-people-fill" />
                  </span>
                  <span className="sidebar-item">Manage Clients</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active d-flex align-items-center mb-1"
                      : "nav-link d-flex align-items-center mb-1"
                  }
                  to="/admin"
                >
                  <span className="icon d-inline-flex">
                    <i className="bi bi-gear" />
                    <i className="bi bi-gear-fill" />
                  </span>
                  <span className="sidebar-item">Profile</span>
                </NavLink>
                {/*.nav-link*/}
              </li>
              {/*.nav-item*/}
            </ul>
          </div>
        </div>
        {/*.sidebar-wrapper*/}
      </aside>
    </>
  );
}

export default AdminSidebar;
