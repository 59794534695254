import React, { useContext, useEffect, useState } from "react";
import AdminSidebar from "../../components/layout/admin/AdminSidebar";
import AdminHeader from "../../components/layout/admin/AdminHeader";
import { AuthContext } from "../../context/Auth";
import { Link, useNavigate } from "react-router-dom";
import { FileCtx } from "../../context/File";
import setAuthToken from "../../utils/setAuthToken";
import {
  constructImageUrl,
  formatDateTime,
  getFileIcon,
  truncateFileName,
  truncateString,
} from "../../utils/helpers";
import { Popconfirm, Progress, Tooltip, message } from "antd";
import axios from "axios";
import {
  BACKEND_URL,
  CLIENT_ACCESS_API_URL,
  CONFIRM_LOCK_API_URL,
  DELETE_FILE_API_URL,
  DUPLICATE_FOLDER_API_URL,
  RENAME_FOLDER_API_URL,
  UPLOAD_API_URL,
} from "../../config/endPoints";
import { UtilsCtx } from "../../context/UtilsContext";
import Select from "react-select";
import { Helmet } from "react-helmet";
import SearchFile from "../../components/layout/admin/SearchFolder";
import { SearchCtx } from "../../context/SearchContext";

function AdminDrive() {
  const token = localStorage.getItem("token");

  const { userInfo, getAdminUsers, adminUsers } = useContext(AuthContext);
  const {
    folderName,
    setFolderName,
    createFolder,
    getFolders,
    folders,
    files,
    getFiles,
    setSFileId,
    setPName,
    setPStatus,
    setPId,
  } = useContext(FileCtx);
  const { handleDownloadAttachment, isSidebarOpen, downloadMultipleFiles } =
    useContext(UtilsCtx);
  const { searchOrder } = useContext(SearchCtx);

  const MAX_ATTACHMENTS = 5;
  const MAX_FILE_SIZE_MB = 50;
  const chunkSize = 500 * 1024;

  const [requirementsFiles, setRequirementsFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [fId, setFId] = useState("");
  const [isLocked, setIsLocked] = useState({
    id: "",
    status: true,
    folderName: "",
  });

  const [cAccess, setCAccess] = useState({ clientAccess: [] });
  const [folderId, setFolderId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedFInfo, setSelectedFInfo] = useState({});

  const handleSetFolder = (i, f) => {
    setFolderId(i);
    setSelectedFInfo(f);
  };

  const handleLockFolder = (i, s, n) => {
    setIsLocked({
      id: i,
      status: s,
      folderName: n,
    });
  };

  const resetSelection = () => {
    setCurrentFileIndex(null);
    setLastUploadedFileIndex(null);
    setCurrentChunkIndex(null);
    setUploadedAttachments([]);
    setRequirementsFiles([]);
    setUploadComplete(false);
    getFiles(token);
  };

  const handleAttachmentsChange = (e) => {
    const files = e.target.files;
    if (files.length > MAX_ATTACHMENTS) {
      message.error(`You can only attach up to ${MAX_ATTACHMENTS} files.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`File size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    setRequirementsFiles(files);
  };

  function readNUploadCurrentChunk() {
    const reader = new FileReader();
    const file = requirementsFiles[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadAChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadAChunk(readerEvent) {
    const file = requirementsFiles[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set("size", file.size);
    params.set("currentChunkIndex", currentChunkIndex);
    params.set("totalChunks", Math.ceil(file.size / chunkSize));
    params.set("fileType", "attachments");
    const headers = { "Content-Type": "application/octet-stream" };
    const url = `${UPLOAD_API_URL}?` + params.toString();
    axios.post(url, data, { headers }).then((response) => {
      const file = requirementsFiles[currentFileIndex];
      const filesize = requirementsFiles[currentFileIndex].size;
      const chunks = Math.ceil(filesize / chunkSize) - 1;
      const isLastChunk = currentChunkIndex === chunks;
      if (isLastChunk) {
        file.finalFilename = response.data.finalFilename;

        setLastUploadedFileIndex(currentFileIndex);
        setCurrentChunkIndex(null);
        setUploadedAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            filename: file.name,
            filePath: response.data,
            filesize: filesize,
          },
        ]);
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1);
      }
    });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === requirementsFiles.length - 1;
    if (isLastFile) {
      setUploadComplete(true);
    }
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (requirementsFiles.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [requirementsFiles.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readNUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  useEffect(() => {
    if (requirementsFiles?.length > 0 && currentFileIndex !== null) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [requirementsFiles, currentFileIndex, uploadComplete]);

  const navigate = useNavigate();

  useEffect(() => {
    document.body.className = "admin-portal";

    return () => {
      document.body.className = "";
    };
  }, []);

  if (userInfo?.userType === "client") {
    navigate("/my-drive");
  }

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      getFolders(token);
      getFiles(token);
      getAdminUsers(token);
    }
  }, []);

  useEffect(() => {
    if (uploadComplete) {
      resetSelection();
    }
  }, [uploadComplete]);

  const uploadProgress =
    requirementsFiles?.length > 0 && currentFileIndex !== null ? (
      <div style={{ textAlign: "center" }}>
        <p style={{ marginBottom: "5px" }}>{`${currentFileIndex + 1}/${
          requirementsFiles?.length
        }`}</p>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "5px",
          }}
        >
          <Progress
            type="circle"
            percent={Math.round(
              (currentChunkIndex /
                Math.ceil(
                  requirementsFiles[currentFileIndex]?.size / chunkSize
                )) *
                100
            )}
            status="active"
            width={40}
            strokeWidth={10}
          />
        </div>
      </div>
    ) : null;

  const confirmDFolder = (id) => {
    axios
      .delete(`${DELETE_FILE_API_URL}/${id}`)
      .then(() => {
        getFolders(token);
        message.success(`Folder deleted successfully.`);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const confirmDFile = (id) => {
    axios
      .delete(`${DELETE_FILE_API_URL}/${id}`)
      .then(() => {
        getFiles(token);
        message.success(`File deleted successfully.`);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const handleNameNIdChange = (i, n) => {
    setFId(i);
    setNewFolderName(n);
  };

  const renameFolder = () => {
    if (!newFolderName.trim()) {
      message.error("Please write a folder name");
      return;
    }

    axios
      .put(`${RENAME_FOLDER_API_URL}/${fId}`, { newFolderName })
      .then(() => {
        getFolders(token);
        message.success("Folder renamed successfully");
        setTimeout(() => {
          const cancelButton = document.querySelector(
            "#staticBackdrop .gray-btn"
          );
          if (cancelButton) {
            cancelButton.click();
          }
        }, 500);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };
  const sFileIdHandler = (oid, n, ps, pi) => {
    setSFileId(oid);
    localStorage.setItem("sFileId", oid);
    setPName(n);
    localStorage.setItem("pName", n);
    setPStatus(ps);
    localStorage.setItem("pStatus", ps);
    setPId(pi);
    localStorage.setItem("pId", pi);
  };

  const confirmLock = () => {
    const newStatus = !isLocked.status;

    axios
      .put(`${CONFIRM_LOCK_API_URL}/${isLocked.id}`, { status: newStatus })
      .then(() => {
        getFolders(token);
        message.success(
          `Folder ${isLocked.status ? "unlocked" : "locked"} successfully`
        );
        setTimeout(() => {
          const cancelButton = document.querySelector("#lockModal .gray-btn");
          if (cancelButton) {
            cancelButton.click();
          }
        }, 500);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const handleUserChange = (selected) => {
    setSelectedUsers(selected);
  };

  useEffect(() => {
    const newOptions = adminUsers?.map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.lastName}`,
      image: user?.imageUrl,
      email: user?.email,
    }));
    setUserOptions(newOptions);
  }, [adminUsers]);

  useEffect(() => {
    if (
      selectedFInfo?.clientAccess &&
      selectedFInfo?.clientAccess?.length > 0
    ) {
      const teamMembers = selectedFInfo?.clientAccess?.map((tm) => ({
        value: tm?._id,
        label: `${tm?.firstName} ${tm?.lastName}`,
        image: tm?.imageUrl,
        email: tm?.email,
      }));
      if (teamMembers.length > 0) {
        setSelectedUsers(teamMembers);
      }
    } else {
      setSelectedUsers([]);
    }
  }, [selectedFInfo]);

  useEffect(() => {
    const selectedUserIds = selectedUsers?.map((tm) => tm?.value || null);

    setCAccess((prevState) => ({
      ...prevState,
      clientAccess: selectedUserIds,
    }));
  }, [selectedUsers]);

  const updateClientAccess = () => {
    axios
      .put(`${CLIENT_ACCESS_API_URL}/${folderId}`, cAccess)
      .then((res) => {
        message.success(res.data.message);
        getFolders(token);
        setTimeout(() => {
          const cancelButton = document.querySelector("#shareModal .gray-btn");
          if (cancelButton) {
            cancelButton.click();
          }
          setCAccess({ clientAccess: [] });
        }, 500);
      })
      .catch((err) => {
        message.error(err.response.data.error);
      });
  };

  const filteredFolders = folders.filter((folder) =>
    folder?.name?.toLowerCase().includes(searchOrder.toLowerCase())
  );

  const filteredFiles = files.filter((file) =>
    file?.name?.toLowerCase().includes(searchOrder.toLowerCase())
  );

  const handleDuplicateFolder = (id) => {
    axios
      .post(`${DUPLICATE_FOLDER_API_URL}/${id}`)
      .then(() => {
        getFolders(token);
        message.success(`Duplicate folder created successfully.`);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Admin Drive - AGA Tax Client Portal</title>
        <meta name="" content="" />
      </Helmet>

      <link rel="stylesheet" href="./css/main.css" />
      <body className="admin-portal">
        {/*=============== Start Dashboard  ===============*/}
        <section
          className={
            isSidebarOpen
              ? "dashboard-wrapper"
              : "dashboard-wrapper sidebar-toggle"
          }
        >
          <AdminSidebar />
          {/*.aside*/}
          <AdminHeader />
          {/*.header*/}
          <main className="page-content shadow-sm d-flex flex-column justify-content-between">
            <div className="container-fluid p-3 p-md-4">
              <div className="row">
                <div className="col-12">
                  <div className="page-header position-relative d-flex flex-column flex-sm-row align-items-md-center justify-content-between gap-3 mb-2">
                    {/* <div className="page-navigator d-flex gap-2">
                      <button className="btn p-0 navi-prev">
                        <i className="bi bi-arrow-left-circle-fill" />
                      </button>
                      <button className="btn p-0 navi-next" disabled>
                        <i className="bi bi-arrow-right-circle-fill" />
                      </button>
                    </div> */}
                    <div className="breadcrumb-wrapper mb-3 d-flex flex-wrap align-items-center gap-3">
                      <div
                        aria-label="breadcrumb"
                        style={{ "--bs-breadcrumb-divider": '">"' }}
                      >
                        <ol className="breadcrumb m-0">
                          <li
                            className="breadcrumb-item fs-4 active"
                            aria-current="page"
                          >
                            My Drive
                          </li>
                        </ol>
                      </div>
                      {uploading ? (
                        uploadProgress
                      ) : (
                        <div className="add-inner-wrapper">
                          <div className="dropdown upload-dropdown">
                            <button
                              className="btn btn-add-new rounded-pill shadow-sm"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="bi bi-plus-lg" />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createFolderModal"
                                >
                                  <span className="d-inline-flex lh-base icon">
                                    <i className="bi bi-folder-plus" />
                                  </span>
                                  New Folder
                                </a>
                              </li>
                              <li>
                                <hr className="dropdown-divider" />
                              </li>
                              <li className="file-attachment">
                                <a className="dropdown-item" href="#">
                                  <span className="d-inline-flex lh-base icon">
                                    <i className="bi bi-file-earmark-arrow-up" />
                                  </span>
                                  File Upload{" "}
                                  <Tooltip
                                    title="Max 50MB/File"
                                    mouseEnterDelay={0.1}
                                  >
                                    <input
                                      type="file"
                                      name="file"
                                      multiple
                                      onChange={handleAttachmentsChange}
                                    />
                                  </Tooltip>{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>

                    {/*breadcrumb-wrapper*/}
                    <div className="page-header-right"></div>
                  </div>
                  <SearchFile />

                  {/*.page-header*/}
                  <div className="page-body position-relative mb-4">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="accordion drive-accordion"
                          id="accordionPanelsStayOpenExample"
                        >
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsFolders">
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseOne"
                                aria-expanded="true"
                                aria-controls="panelsStayOpen-collapseOne"
                              >
                                <span>
                                  {filteredFolders?.filter(
                                    (f) => f.parent === null
                                  ).length > 1
                                    ? "Folders"
                                    : "Folder"}
                                </span>
                                <span>
                                  (
                                  {
                                    filteredFolders?.filter(
                                      (f) => f.parent === null
                                    ).length
                                  }
                                  )
                                </span>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpen-collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="panelsFolders"
                            >
                              <div className="accordion-body">
                                <div className="row g-3">
                                  {filteredFolders
                                    ?.filter((f) => f.parent === null)
                                    .map((f, i) => (
                                      <div
                                        className="col-md-4 col-sm-6"
                                        key={i}
                                      >
                                        <div className="listing-item">
                                          <div className="icon-wrapper">
                                            <span className="icon">
                                              <i className="bi bi-folder-fill" />
                                            </span>
                                          </div>
                                          <div className="item-text">
                                            <Link
                                              to="/admin-inner-folder"
                                              style={{
                                                textDecoration: "none",
                                                color: "#212529",
                                              }}
                                              onClick={() =>
                                                sFileIdHandler(
                                                  f._id,
                                                  f?.name,
                                                  f?.isLocked,
                                                  f?.parent
                                                )
                                              }
                                            >
                                              <span>
                                                {truncateString(f?.name, 6)}
                                              </span>
                                            </Link>
                                          </div>
                                          <div className="item-action dropdown">
                                            <a
                                              href="#"
                                              role="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="bi bi-three-dots-vertical" />
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end border py-0 shadow-sm">
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#staticBackdrop"
                                                  onClick={() =>
                                                    handleNameNIdChange(
                                                      f?._id,
                                                      f?.name
                                                    )
                                                  }
                                                >
                                                  <i className="bi bi-pencil" />{" "}
                                                  Rename
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#shareModal"
                                                  onClick={() =>
                                                    handleSetFolder(f._id, f)
                                                  }
                                                >
                                                  <i className="bi bi-person-add" />{" "}
                                                  Share
                                                </a>
                                              </li>
                                              {f?.isLocked ? (
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#lockModal"
                                                    onClick={() =>
                                                      handleLockFolder(
                                                        f?._id,
                                                        f?.isLocked,
                                                        f?.name
                                                      )
                                                    }
                                                  >
                                                    <i className="bi bi-unlock" />
                                                    Unlock
                                                  </a>
                                                </li>
                                              ) : (
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#lockModal"
                                                    onClick={() =>
                                                      handleLockFolder(
                                                        f?._id,
                                                        f?.isLocked,
                                                        f?.name
                                                      )
                                                    }
                                                  >
                                                    <i className="bi bi-lock" />
                                                    Lock
                                                  </a>
                                                </li>
                                              )}

                                              <li>
                                                <Popconfirm
                                                  title="Confirm Duplicate"
                                                  description={`Are you sure you want to duplicate this folder?`}
                                                  onConfirm={() =>
                                                    handleDuplicateFolder(
                                                      f?._id
                                                    )
                                                  }
                                                  okText="Yes"
                                                  cancelText="No"
                                                >
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    id="deleteToastBtn"
                                                  >
                                                    <i class="bi bi-copy"></i>{" "}
                                                    Duplicate
                                                  </a>
                                                </Popconfirm>
                                              </li>
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  onClick={() =>
                                                    downloadMultipleFiles(
                                                      f?._id
                                                    )
                                                  }
                                                >
                                                  <i className="bi bi-download" />{" "}
                                                  Download Files
                                                </a>
                                              </li>
                                              <li>
                                                <Popconfirm
                                                  title="Confirm Delete"
                                                  description={`Are you sure you want to delete this folder?`}
                                                  onConfirm={() =>
                                                    confirmDFolder(f?._id)
                                                  }
                                                  okText="Yes"
                                                  cancelText="No"
                                                >
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    id="deleteToastBtn"
                                                  >
                                                    <i className="bi bi-trash" />{" "}
                                                    Delete
                                                  </a>
                                                </Popconfirm>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        {/*.listing-item*/}
                                      </div>
                                    ))}

                                  {/*.col-grid*/}
                                </div>
                                {/*..row*/}
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsFiles">
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseTwo"
                                aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseTwo"
                              >
                                <span>
                                  {filteredFiles?.filter(
                                    (f) => f.parent === null
                                  ).length > 1
                                    ? "Files"
                                    : "File"}
                                </span>
                                <span>
                                  (
                                  {
                                    filteredFiles?.filter(
                                      (f) => f.parent === null
                                    ).length
                                  }
                                  )
                                </span>
                              </button>
                            </h2>
                            <div
                              id="panelsStayOpen-collapseTwo"
                              className="accordion-collapse collapse show"
                              aria-labelledby="panelsFiles"
                            >
                              <div className="accordion-body">
                                <div className="row g-3">
                                  {filteredFiles
                                    ?.filter((f) => f.parent === null)
                                    .map((f, i) => (
                                      <div
                                        className="col-md-4 col-sm-6"
                                        key={i}
                                      >
                                        <div className="listing-item">
                                          <div className="icon-wrapper">
                                            <span className="icon">
                                              <i
                                                className={getFileIcon(
                                                  `${BACKEND_URL}/${f?.filePath}`
                                                )}
                                              />
                                            </span>
                                          </div>
                                          <div className="item-text">
                                            <span>
                                              {truncateFileName(
                                                f?.filename,
                                                27
                                              )}
                                              {` - ${formatDateTime(
                                                f?.createdAt
                                              )}`}
                                            </span>
                                          </div>
                                          <div className="item-action dropdown">
                                            <a
                                              href="#"
                                              role="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="bi bi-three-dots-vertical" />
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end border py-0 shadow-sm">
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  onClick={() =>
                                                    handleDownloadAttachment(
                                                      f?._id,
                                                      f?.filename,
                                                      f?.filePath
                                                    )
                                                  }
                                                >
                                                  <i className="bi bi-download" />{" "}
                                                  Download
                                                </a>
                                              </li>

                                              <li>
                                                <Popconfirm
                                                  title="Confirm Delete"
                                                  description={`Are you sure you want to delete this file?`}
                                                  onConfirm={() =>
                                                    confirmDFile(f?._id)
                                                  }
                                                  okText="Yes"
                                                  cancelText="No"
                                                >
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    id="deleteToastBtn"
                                                  >
                                                    <i className="bi bi-trash" />{" "}
                                                    Delete
                                                  </a>
                                                </Popconfirm>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        {/*.listing-item*/}
                                      </div>
                                    ))}

                                  {/*.col-grid*/}
                                </div>
                                {/*..row*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*.page-body*/}
                </div>
                {/*.col-grid*/}
              </div>
              {/*.row*/}
            </div>
            {/*.container-fluid*/}
          </main>
        </section>
        {/* Modal Rename */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Rename
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control"
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                />
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn gray-btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn px-4"
                  onClick={renameFolder}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Create Folder */}
        <div
          className="modal fade"
          id="createFolderModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="createFolderLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Create New Folder
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                />
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn gray-btn"
                  data-bs-dismiss="modal"
                  onClick={() => setFolderName("Untitled folder")}
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn px-4"
                  onClick={createFolder}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Share */}
        <div
          className="modal fade"
          id="shareModal"
          tabIndex={-1}
          aria-labelledby="shareModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className="modal-title fs-5" id="shareModalLabel">
                  Share With
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <Select
                  isMulti
                  placeholder="Add client ..."
                  options={userOptions}
                  value={selectedUsers}
                  onChange={handleUserChange}
                />
                <div className="mt-4">
                  <h6 className="mb-3">Clients with access</h6>
                  <ul className="list-unstyled p-access-list d-flex flex-column gap-2">
                    {selectedUsers?.map((user) => (
                      <li
                        key={user.value}
                        className="d-flex align-items-center justify-content-between gap-2"
                      >
                        <div className="list-left d-flex align-items-center gap-2">
                          <div className="img-wrapper d-block rounded-pill overflow-hidden me-sm-1">
                            {/* Assuming you have an image URL in the user object */}
                            {user?.image ? (
                              <img
                                className="img-fluid"
                                src={constructImageUrl(user?.image)}
                                alt={user?.label}
                              />
                            ) : (
                              <img
                                className="img-fluid"
                                src="./assets/images/client.png"
                                alt={user?.label}
                              />
                            )}
                          </div>
                          <div className="list-text">
                            <h6 className="fw-semibold mb-0">{user?.label}</h6>
                            <span className="text-secondarys small">
                              {user?.email}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn gray-btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn px-4"
                  onClick={updateClientAccess}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Lock File */}
        <div
          className="modal fade"
          id="lockModal"
          tabIndex={-1}
          aria-labelledby="lockModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1
                  className="modal-title fs-5 d-inline-flex align-items-center gap-2"
                  id="lockModalLabel"
                >
                  <span>
                    <i
                      className={`bi bi-${isLocked.status ? "unlock" : "lock"}`}
                    />
                  </span>
                  Confirm {isLocked.status ? "Unlock" : "Lock"}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to {isLocked.status ? "unlock" : "lock"}{" "}
                  this folder?
                </p>
                <div className="listing-item d-inline-flex bg-light shadow-none">
                  <div className="icon-wrapper">
                    <span className="icon">
                      <i className="bi bi-folder-fill" />
                    </span>
                  </div>
                  <div className="item-text">
                    <span>{isLocked?.folderName}</span>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn gray-btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn primary-btn px-4"
                  onClick={confirmLock}
                >
                  {isLocked.status ? "Unlock" : "Lock"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          <div
            id="liveToast"
            className="toast shadow-sm p-1"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header border-0 d-flex align-items-center gap-2">
              <span className="icon">
                <i className="bi bi-trash3-fill" />
              </span>
              <strong className="me-auto fw-semibold">
                Develompent folder deleted
              </strong>
            </div>
          </div>
        </div>
      </body>

      {/*delete toast*/}
      {/*=============== End Dashboard ===============*/}
    </>
  );
}

export default AdminDrive;
