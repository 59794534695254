import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FileCtx } from "../../context/File";
import setAuthToken from "../../utils/setAuthToken";
import { truncateString } from "../../utils/helpers";

import ClientSidebar from "../../components/layout/client/ClientSidebar";
import ClientHeader from "../../components/layout/client/ClientHeader";
import { UtilsCtx } from "../../context/UtilsContext";
import { Helmet } from "react-helmet";

function MyDrive() {
  const { isSidebarOpen } = useContext(UtilsCtx);

  const token = localStorage.getItem("token");

  const {
    folders,
    getFiles,
    setSFileId,
    setPName,
    setPStatus,
    setPId,
    getTopParentFolder,
    topParentFolder,
  } = useContext(FileCtx);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      getTopParentFolder(token);
      getFiles(token);
    }
  }, []);

  const sFileIdHandler = (oid, n, ps, pi) => {
    setSFileId(oid);
    localStorage.setItem("sFileId", oid);
    setPName(n);
    localStorage.setItem("pName", n);
    setPStatus(ps);
    localStorage.setItem("pStatus", ps);
    setPId(pi);
    localStorage.setItem("pId", pi);
  };

  return (
    <>
      <Helmet>
        <title>Drive - AGA Tax Client Portal</title>
        <meta name="" content="" />
      </Helmet>

      <link rel="stylesheet" href="./css/main.css" />
      {/*=============== Start Dashboard  ===============*/}
      <section
        className={
          isSidebarOpen
            ? "dashboard-wrapper"
            : "dashboard-wrapper sidebar-toggle"
        }
      >
        <ClientSidebar />
        {/*.aside*/}
        <ClientHeader />
        {/*.header*/}
        <main className="page-content shadow-sm d-flex flex-column justify-content-between">
          <div className="container-fluid p-3 p-md-4">
            <div className="row">
              <div className="col-12">
                <div className="page-header position-relative d-flex flex-column flex-sm-row align-items-md-center justify-content-between gap-3 mb-4">
                  <div className="breadcrumb-wrapper mb-3 d-flex flex-wrap align-items-center gap-3">
                    <div
                      aria-label="breadcrumb"
                      style={{ "--bs-breadcrumb-divider": '">"' }}
                    >
                      <ol className="breadcrumb m-0">
                        <li
                          className="breadcrumb-item fs-4 active"
                          aria-current="page"
                        >
                          My Drive
                        </li>
                      </ol>
                    </div>
                    {/* {uploading ? (
                      uploadProgress
                    ) : (
                      <div className="add-inner-wrapper">
                        <div className="file-attachment">
                          <button className="btn btn-add-new rounded-pill shadow-sm">
                            <i className="bi bi-plus-lg" />
                            <Tooltip
                              title="Max 50MB/File"
                              mouseEnterDelay={0.1}
                            >
                              <input
                                type="file"
                                name="file"
                                multiple
                                onChange={handleAttachmentsChange}
                              />
                            </Tooltip>{" "}
                          </button>
                        </div>
                      </div>
                    )} */}
                  </div>
                  {/*breadcrumb-wrapper*/}
                  <div className="page-header-right"></div>
                </div>
                {/*.page-header*/}
                <div className="page-body position-relative mb-4">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="accordion drive-accordion"
                        id="accordionPanelsStayOpenExample"
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="panelsFolders">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseOne"
                            >
                              <span>{"Folder"}</span>
                              <span>({topParentFolder?.name ? "1" : 0})</span>
                            </button>
                          </h2>
                          {topParentFolder?.name && (
                            <div
                              id="panelsStayOpen-collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="panelsFolders"
                            >
                              <div className="accordion-body">
                                <div className="row g-3">
                                  <div className="col-md-4 col-sm-6">
                                    <div className="listing-item">
                                      <div className="icon-wrapper">
                                        <span className="icon">
                                          <i className="bi bi-folder-fill" />
                                        </span>
                                      </div>
                                      <div className="item-text">
                                        <Link
                                          to="/inner-folder"
                                          style={{
                                            textDecoration: "none",
                                            color: "#212529",
                                          }}
                                          onClick={() =>
                                            sFileIdHandler(
                                              topParentFolder?._id,
                                              topParentFolder?.name,
                                              topParentFolder?.isLocked,
                                              topParentFolder?.parent
                                            )
                                          }
                                        >
                                          <span>
                                            {truncateString(
                                              topParentFolder?.name,
                                              6
                                            )}
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                    {/*.listing-item*/}
                                  </div>

                                  {/*.col-grid*/}
                                </div>
                                {/*..row*/}
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <div className="accordion-item">
                          <h2 className="accordion-header" id="panelsFiles">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTwo"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwo"
                            >
                              <span>
                                {files?.filter((f) => f.parent === null)
                                  .length > 1
                                  ? "Files"
                                  : "File"}
                              </span>
                              <span>
                                (
                                {files?.filter((f) => f.parent === null).length}
                                )
                              </span>
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsFiles"
                          >
                            <div className="accordion-body">
                              <div className="row g-3">
                                {files
                                  ?.filter((f) => f.parent === null)
                                  .map((f, i) => (
                                    <div className="col-md-4 col-sm-6" key={i}>
                                      <div className="listing-item">
                                        <div className="icon-wrapper">
                                          <span className="icon">
                                            <i
                                              className={getFileIcon(
                                                `${BACKEND_URL}/${f?.filePath}`
                                              )}
                                            />
                                          </span>
                                        </div>
                                        <div className="item-text">
                                          <span>
                                            {truncateFileName(f?.filename, 40)}
                                          </span>
                                        </div>
                                        <div className="item-action dropdown">
                                          <a
                                            href="#"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="bi bi-three-dots-vertical" />
                                          </a>
                                          <ul className="dropdown-menu dropdown-menu-end border py-0 shadow-sm">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={() =>
                                                  handleDownloadAttachment(
                                                    f?._id,
                                                    f?.filename,
                                                    f?.filePath
                                                  )
                                                }
                                              >
                                                <i className="bi bi-download" />{" "}
                                                Download
                                              </a>
                                            </li>

                                            <li>
                                              <Popconfirm
                                                title="Confirm Delete"
                                                description={`Are you sure you want to delete this file?`}
                                                onConfirm={() =>
                                                  confirmDFile(f?._id)
                                                }
                                                okText="Yes"
                                                cancelText="No"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  id="deleteToastBtn"
                                                >
                                                  <i className="bi bi-trash" />{" "}
                                                  Delete
                                                </a>
                                              </Popconfirm>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                              </div>
                              
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/*.page-body*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
        </main>
        {/*.page-content"*/}
      </section>

      {/*=============== End Dashboard ===============*/}
    </>
  );
}

export default MyDrive;
