import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/Auth";
import { Link } from "react-router-dom";
import { constructImageUrl } from "../../../utils/helpers";
import { UtilsCtx } from "../../../context/UtilsContext";

function AdminHeader() {
  const token = localStorage.getItem("token");

  const { logout, userInfo, fetchUserInfo } = useContext(AuthContext);
  const { isSidebarOpen, setIsSidebarOpen } = useContext(UtilsCtx);

  const handleSidebarOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (token) {
      fetchUserInfo(token);
    }
  }, []);
  return (
    <>
      <header className="header">
        <nav className="dashboard-nav position-fixed top-0 d-flex align-items-center pe-3 ps-3 ps-lg-0 mb-0">
          <a
            id="sidebarBtn"
            className="btn-hamburger d-inline-flex p-2 d-lg-none"
            onClick={handleSidebarOpen}
          >
            <span className="d-block position-relative" />
          </a>
          {/*.btn-hamburger*/}
          <div className="w-100 h-100 d-flex">
            <div className="nav-icons-wrapper d-flex flex-wrap align-items-center gap-3 gap-sm-4 ms-auto">
              <div className="dropdown profile-dropdown dropdown-end d-flex">
                <a
                  className="d-flex align-items-center text-white gap-2 text-decoration-none dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="profile d-block rounded-pill overflow-hidden me-sm-1">
                    {userInfo?.imageUrl ? (
                      <img
                        src={constructImageUrl(userInfo?.imageUrl)}
                        className="rounded-pill user-img img-fluid"
                        id="pfImg"
                        width={110}
                        height={110}
                        alt
                      />
                    ) : (
                      <span className="user-name-txt">
                        {`${
                          userInfo?.firstName
                            ? userInfo.firstName.charAt(0).toUpperCase()
                            : ""
                        }${
                          userInfo?.lastName
                            ? userInfo.lastName.charAt(0).toUpperCase()
                            : ""
                        }`}
                      </span>
                    )}
                  </div>
                  {/*.profile*/}
                  <div className="profile-bio">
                    <h6 className="fw-semibold mb-0">{userInfo?.firstName}</h6>
                  </div>
                  {/*.profile-bio*/}
                </a>
                <ul className="dropdown-menu dropdown-menu-end border-0 py-0">
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center gap-2 py-2"
                      to="/admin"
                    >
                      <span className="d-inline-flex lh-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={18}
                          height={18}
                          fill="currentColor"
                          className="bi bi-person"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                        </svg>
                      </span>
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="/admin-drive"
                    >
                      <span className="d-inline-flex lh-base me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-hdd-stack"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 10a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1zM2 9a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2z" />
                          <path d="M5 11.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0M14 3a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zM2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" />
                          <path d="M5 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
                        </svg>
                      </span>
                      My Drive
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      onClick={() => {
                        logout();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="d-inline-flex lh-base me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          fill="currentColor"
                          className="bi bi-box-arrow-in-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                          />
                        </svg>
                      </span>
                      Sign Out
                    </a>
                  </li>
                </ul>
                {/*.dropdown-menu*/}
              </div>
              {/*.dropdown*/}
            </div>
            {/*.nav-icons-wrapper*/}
          </div>
        </nav>
        {/*dashboard-nav*/}
      </header>
    </>
  );
}

export default AdminHeader;
