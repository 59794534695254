import { message } from "antd";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RESET_PASSWORD_API_URL } from "../../../../config/endPoints";
import { AuthContext } from "../../../../context/Auth";
import setAuthToken from "../../../../utils/setAuthToken";

function UpdatePassword({ token }) {
  const navigate = useNavigate();
  const { fetchUserInfo } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!password.newPassword || !password.confirmPassword) {
      message.error("Please fill in both password fields.");
      setLoading(false);
      return;
    }

    if (password.confirmPassword !== password.newPassword) {
      message.error(
        "Confirm password must match new password. Please try again."
      );
      setLoading(false);
    } else {
      axios
        .post(RESET_PASSWORD_API_URL, {
          ...password,
          token,
        })
        .then((res) => {
          setLoading(false);
          message.success("Password Updated Successfully.");
          const token = res.data.token;
          if (token) {
            setAuthToken(token);
            localStorage.setItem("token", token);
            navigate(
              res?.data?.userType === "admin"
                ? "/admin-dashboard"
                : "/dashboard"
            );
            fetchUserInfo(token);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <form action="#" className="sign-in-form">
        <div className="form-title text-center mb-md-5 mb-4">
          <h3 className="display-6 fw-semibold mb-3">Reset Password</h3>
          <p className="text-muted">Enter your new password below:</p>
        </div>
        <div className="mb-3">
          <input
            id="newpassword"
            name="newPassword"
            type="password"
            onChange={handleChange}
            className="form-control bg-white border-0 px-3"
            placeholder="Password"
            autofocus
          />
        </div>
        <div className="mb-3">
          <input
            id="confirmpassword"
            name="confirmPassword"
            type="password"
            onChange={handleChange}
            className="form-control bg-white border-0 px-3"
            placeholder="Confirm Password"
            autofocus
          />
        </div>
        <button
          type="submit"
          className="btn primary-btn shadow w-100 mb-2"
          onClick={submit}
        >
          {loading && (
            <span className="spinner-grow spinner-grow-sm me-2"></span>
          )}
          {!loading ? "Update Password" : "Updating Password"}
        </button>
        <p className="mt-4 text-center text-muted">
          Remember your password?{" "}
          <Link
            to="/"
            className="text-decoration-none text-decoration-underline create-account-action"
          >
            Login
          </Link>
        </p>
      </form>
    </>
  );
}

export default UpdatePassword;
