import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FileCtx } from "../../context/File";
import setAuthToken from "../../utils/setAuthToken";
import {
  formatDateTime,
  getFileIcon,
  truncateFileName,
  truncateString,
} from "../../utils/helpers";
import { BACKEND_URL, DELETE_FILE_API_URL } from "../../config/endPoints";
import axios from "axios";
import { Popconfirm, message } from "antd";
import { UtilsCtx } from "../../context/UtilsContext";
import ClientSidebar from "../../components/layout/client/ClientSidebar";
import ClientHeader from "../../components/layout/client/ClientHeader";
import { Helmet } from "react-helmet";

function Dashboard() {
  const token = localStorage.getItem("token");

  const { getAllFiles, allFiles, setSFileId, setPName, setPStatus, setPId } =
    useContext(FileCtx);
  const { handleDownloadAttachment, isSidebarOpen, downloadMultipleFiles } =
    useContext(UtilsCtx);

  const [selectedOption, setSelectedOption] = useState(null);

  const sFileIdHandler = (oid, n, ps, pi) => {
    setSFileId(oid);
    localStorage.setItem("sFileId", oid);
    setPName(n);
    localStorage.setItem("pName", n);
    setPStatus(ps);
    localStorage.setItem("pStatus", ps);
    setPId(pi);
    localStorage.setItem("pId", pi);
  };

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      getAllFiles(token);
    }
  }, []);

  const confirm = (id, ft) => {
    axios
      .delete(`${DELETE_FILE_API_URL}/${id}`)
      .then(() => {
        getAllFiles(token);
        message.success(
          `${ft === "folder" ? "Folder" : "File"} deleted successfully.`
        );
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Dashboard - AGA Tax Client Portal</title>
        <meta name="" content="" />
      </Helmet>

      <link rel="stylesheet" href="./css/main.css" />
      {/*=============== Start Dashboard  ===============*/}
      <section
        className={
          isSidebarOpen
            ? "dashboard-wrapper"
            : "dashboard-wrapper sidebar-toggle"
        }
      >
        <ClientSidebar />
        {/*.aside*/}
        <ClientHeader />
        {/*.header*/}
        <main className="page-content shadow-sm d-flex flex-column justify-content-between">
          <div className="container-fluid p-3 p-md-4">
            <div className="row">
              <div className="col-12">
                <div className="page-header d-flex flex-column flex-sm-row align-items-md-center justify-content-between gap-3 mb-4">
                  <div className="breadcrumb-wrapper">
                    <h3 className="fs-4">
                      {allFiles?.length === 0
                        ? "Your Dashboard Is Currently Empty."
                        : "Dashboard: Recent Folders and Files"}
                    </h3>
                  </div>
                  {/*breadcrumb-wrapper*/}
                  <div className="page-header-right">
                    <div className="d-inline-flex rounded-pill overflow-hidden">
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                        id="option1"
                        autoComplete="off"
                        checked={selectedOption === "Files"}
                        onChange={() => setSelectedOption("Files")}
                      />
                      <label
                        className="btn btn-light rounded-0"
                        htmlFor="option1"
                      >
                        <i className="bi bi-card-text" /> Files
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="options"
                        id="option2"
                        autoComplete="off"
                        checked={selectedOption === "Folders"}
                        onChange={() => setSelectedOption("Folders")}
                      />
                      <label
                        className="btn btn-light rounded-0"
                        htmlFor="option2"
                      >
                        <i className="bi bi-folder" /> Folders
                      </label>
                    </div>
                  </div>
                </div>
                {/*.page-header*/}
                <div className="page-body position-relative mb-4">
                  <div className="row g-3">
                    {allFiles
                      ?.filter(
                        (f) =>
                          selectedOption === null ||
                          (selectedOption === "Files"
                            ? f.type === "file"
                            : f.type === "folder")
                      )
                      .slice(0, 18)
                      .map((f, i) => (
                        <div className="col-md-4 col-sm-6" key={i}>
                          <div className="listing-item">
                            <div className="icon-wrapper">
                              <span className="icon">
                                <i
                                  className={
                                    f?.type === "folder"
                                      ? "bi bi-folder-fill"
                                      : getFileIcon(
                                          `${BACKEND_URL}/${f?.filePath}`
                                        )
                                  }
                                />
                              </span>
                            </div>
                            <div className="item-text">
                              <Link
                                to={f?.type === "file" ? "" : "/inner-folder"}
                                style={{
                                  textDecoration: "none",
                                  color: "#212529",
                                }}
                                onClick={() =>
                                  sFileIdHandler(
                                    f._id,
                                    f?.name,
                                    f?.isLocked,
                                    f?.parent
                                  )
                                }
                              >
                                {f?.type === "file"
                                  ? truncateFileName(f?.filename, 27)
                                  : truncateString(f?.name, 5)}{" "}
                                {f?.type === "file"
                                  ? ` - ${formatDateTime(f?.createdAt)}`
                                  : ""}
                              </Link>
                            </div>
                            {f?.type === "file" && (
                              <>
                                <div className="item-action dropdown">
                                  <a
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-three-dots-vertical" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-end border py-0 shadow-sm">
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() =>
                                          handleDownloadAttachment(
                                            f?._id,
                                            f?.filename,
                                            f?.filePath
                                          )
                                        }
                                      >
                                        <i className="bi bi-download" />{" "}
                                        Download
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </>
                            )}
                            {f?.type === "folder" && (
                              <>
                                <div className="item-action dropdown">
                                  <a
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-three-dots-vertical" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-end border py-0 shadow-sm">
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() =>
                                          downloadMultipleFiles(f?._id)
                                        }
                                      >
                                        <i className="bi bi-download" />{" "}
                                        Download Files
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </>
                            )}
                          </div>
                          {/*.listing-item*/}
                        </div>
                      ))}
                  </div>
                  {/*..row*/}
                  {/* <div className="row mt-5">
                      <div className="col-12 text-center">
                        <a href="#" className="btn primary-btn">
                          Show more
                        </a>
                      </div>
                    </div> */}
                </div>
                {/*.page-body*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
        </main>
        {/*.page-content"*/}
      </section>

      {/*delete toast*/}
      {/*=============== End Dashboard ===============*/}
    </>
  );
}

export default Dashboard;
