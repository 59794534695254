import {
  APP_STATUS,
  LOCAL_API_URL,
  LOCAL_BACKEND_URL,
  LOCAL_DOMAIN_URL,
  PRODUCTION_API_URL,
  PRODUCTION_BACKEND_URL,
  PRODUCTION_DOMAIN_URL,
} from "./env";

export const DOMAIN_URL =
  APP_STATUS === "production" ? PRODUCTION_DOMAIN_URL : LOCAL_DOMAIN_URL;
export const BACKEND_URL =
  APP_STATUS === "production" ? PRODUCTION_BACKEND_URL : LOCAL_BACKEND_URL;
export const API_URL =
  APP_STATUS === "production" ? PRODUCTION_API_URL : LOCAL_API_URL;

// NodeJs Backend URLS
export const LOGIN_API_URL = API_URL + "/login";
export const REGISTER_API_URL = API_URL + "/register";
export const GET_USER_INFO_API_URL = API_URL + "/info";
export const UPDATE_USER_INFO_API_URL = API_URL + "/update";
export const FORGET_PASSWORD_API_URL = API_URL + "/forget-password";
export const VERFIY_LINK_TOKEN_API_URL = API_URL + "/verify-token";
export const RESET_PASSWORD_API_URL = API_URL + "/reset-password";
export const GET_ADMIN_USERS_API_URL = API_URL + "/getausers";
export const UPDATE_ADMIN_USER_API_URL = API_URL + "/updateauser";
export const DELETE_ADMIN_USER_API_URL = API_URL + "/auserdelete";
export const CREATE_FOLDER_API_URL = API_URL + "/create-folder";
export const GET_ALL_FILES_API_URL = API_URL + "/get-all-files";
export const GET_INNER_FILES_API_URL = API_URL + "/get-inner-files";
export const GET_FOLDERS_API_URL = API_URL + "/get-folders";
export const GET_TOP_PARENT_FOLDER_API_URL = API_URL + "/get-top-parent-folder";
export const GET_FILES_API_URL = API_URL + "/get-files";
export const UPLOAD_API_URL = API_URL + "/upload";
export const DELETE_FILE_API_URL = API_URL + "/delete-file";
export const DOWNLOAD_ATTACHMENT_API_URL = API_URL + "/downloadattachment";
export const DOWNLOAD_MULTIPLE_FILES_API_URL = API_URL + "/downloadfiles";
export const RENAME_FOLDER_API_URL = API_URL + "/rename-folder";
export const CONFIRM_LOCK_API_URL = API_URL + "/confirm-lock";
export const CONFIRM_COMPLETE_API_URL = API_URL + "/confirm-complete";
export const CLIENT_ACCESS_API_URL = API_URL + "/client-access";
export const DUPLICATE_FOLDER_API_URL = API_URL + "/duplicate";
