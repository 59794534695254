import React from "react";
import { Link } from "react-router-dom";
import { DOMAIN_URL } from "../../src/config/endPoints";
import { Helmet } from "react-helmet";

function PageNotFound() {
  return (
    <>
     <Helmet>
        <title>Page Not Found - AGA Tax Client Portal</title>
        <meta name="" content="" />
    </Helmet>

      {/*====================== Start 404 Error ======================*/}
      <link rel="stylesheet" href="./css/main.css" />
      <section className="oms-404 py-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-xl-6 my-3 text-center">
              <figure className="mb-4 mb-md-5">
                <img
                  src={`${DOMAIN_URL}/assets/images/404-error.png`}
                  width={220}
                  className="img-fluid"
                  alt
                />
              </figure>
              <h3 className="fs-1 mb-3">Sorry! page did not found</h3>
              <p className="fs-6 mb-5 text-muted">
                The page you are looking for might have been removed or is
                temporarily unavailable.
              </p>

              <div className="row mt-5">
                <div className="col-12 text-center">
                  <Link to="/" className="btn primary-btn">
                    Back to Homepage
                  </Link>
                </div>
              </div>
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>

        {/*.container*/}
      </section>
      {/*====================== End 404 Error ======================*/}
    </>
  );
}

export default PageNotFound;
