import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../context/Auth";
import { message } from "antd";
import { cloudName, uploadPreset } from "../../../../config/env";
import { constructImageUrl } from "../../../../utils/helpers";
import wtAxios from "../../../../utils/wtAxios";

function ClientProfile() {
  const {
    userInfo,
    updateUser,
    confirmPassword,
    setConfirmPassword,
    updateUserInfo,
    setUpdateUserInfo,
    cancelUpdate,
    fetchUserInfo,
    editing,
    setEditing,
  } = useContext(AuthContext);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "confirmPassword") {
      setConfirmPassword(value);
    } else {
      setUpdateUserInfo({ ...updateUserInfo, [id]: value });
    }
    userInfo[id] = value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser(updateUserInfo);
  };

  function handleImageUpload(e) {
    const file = e.target.files[0];

    if (file?.size > 2 * 1024 * 1024) {
      message.error("Only 2MB image is allowed!");
      return;
    }

    const fileName = file?.name;
    const ext = fileName
      ?.substring(fileName?.lastIndexOf(".") + 1)
      .toLowerCase();

    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "JPEG",
      "JPG",
      "PNG",
      "GIF",
    ];

    if (!allowedExtensions.includes(ext)) {
      message.error("Only image files (JPEG, JPG, PNG, GIF) are allowed.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", uploadPreset);
    const messageDuration = file.size > 500 * 1024 ? 2 : 1;
    message.info("Updating Profile Image", messageDuration);
    wtAxios
      .post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formData
      )
      .then((res) => {
        const imgUrl = res.data.secure_url;
        const updatedUserInfo = {
          ...updateUserInfo,
          imageUrl: imgUrl,
        };
        updateUser(updatedUserInfo);
      })
      .catch((error) => {
        console.error("Error uploading image: ", error);
      });
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserInfo(token);
    }
  }, []);

  return (
    <>
      <main className="page-content shadow-sm d-flex flex-column justify-content-between">
        <div className="container-fluid p-3 p-md-4">
          <div className="row">
            <div className="col-12">
              <div className="page-header d-flex flex-column flex-sm-row align-items-md-center justify-content-between gap-3 mb-4">
                <div className="breadcrumb-wrapper">
                  <h3 className="fs-4">My Profile</h3>
                </div>
                {/*breadcrumb-wrapper*/}
              </div>
              {/*.page-header*/}
              <div className="page-body position-relative mb-4">
                <div className="row g-3">
                  <div className="col-12">
                    <div className="user-img-edit mb-2">
                      <div className="user-img-wrapper profile-img mb-2">
                        <label className="label-overlay" htmlFor="file">
                          <span className="icon">
                            <i className="bi bi-camera" />
                          </span>
                          <span className="overylay-txt">
                            {userInfo?.imageUrl ? "Change" : "Upload"}
                          </span>
                        </label>
                        <input
                          id="file"
                          type="file"
                          onChange={handleImageUpload}
                        />
                        {userInfo?.imageUrl ? (
                          <img
                            src={constructImageUrl(userInfo?.imageUrl)}
                            className="rounded-pill user-img"
                            id="pfImg"
                            width={110}
                            height={110}
                            alt
                          />
                        ) : (
                          <span className="user-name-txt">
                            {`${
                              userInfo?.firstName
                                ? userInfo.firstName.charAt(0).toUpperCase()
                                : ""
                            }${
                              userInfo?.lastName
                                ? userInfo.lastName.charAt(0).toUpperCase()
                                : ""
                            }`}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {!editing && (
                    <div className="col-12">
                      <div className="col-sm-7 col-lg-5">
                        <label className="form-label fw-semibold">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={`${userInfo?.firstName} ${userInfo?.lastName}`}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {editing && (
                    <div className="col-12">
                      <div className="col-sm-7 col-lg-5">
                        <label
                          htmlFor="firstName"
                          className="form-label fw-semibold"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          onChange={handleChange}
                          value={userInfo?.firstName ? userInfo?.firstName : ""}
                        />
                      </div>
                    </div>
                  )}
                  {editing && (
                    <div className="col-12">
                      <div className="col-sm-7 col-lg-5">
                        <label
                          htmlFor="lastName"
                          className="form-label fw-semibold"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          onChange={handleChange}
                          value={userInfo?.lastName ? userInfo?.lastName : ""}
                        />
                      </div>
                    </div>
                  )}
                  {/*.col-grid*/}
                  <div className="col-12">
                    <div className="col-sm-7 col-lg-5">
                      <label htmlFor="email" className="form-label fw-semibold">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder
                        defaultValue
                        onChange={handleChange}
                        value={userInfo?.email ? userInfo?.email : ""}
                        disabled={!editing}
                      />
                    </div>
                  </div>
                  {/*.col-grid*/}
                  <div className="col-12">
                    <div className="col-sm-7 col-lg-5">
                      <label
                        htmlFor="password"
                        className="form-label fw-semibold"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        onChange={handleChange}
                        value={
                          updateUserInfo.password ? updateUserInfo.password : ""
                        }
                        disabled={!editing}
                      />
                    </div>
                  </div>
                  {/*.col-grid*/}
                  {editing && (
                    <div className="col-12">
                      <div className="col-sm-7 col-lg-5">
                        <label
                          htmlFor="confirmPassword"
                          className="form-label fw-semibold"
                        >
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          disabled={!editing}
                          onChange={handleChange}
                          value={confirmPassword}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/*..row*/}
                <div className="row mt-4 mt-md-5">
                  <div className="col-12">
                    {!editing && (
                      <a
                        href="#"
                        className="btn gray-btn"
                        onClick={() => setEditing(true)}
                      >
                        Edit
                      </a>
                    )}
                    {editing && (
                      <>
                        <a
                          href="#"
                          className="btn gray-btn"
                          onClick={cancelUpdate}
                        >
                          Cancel
                        </a>
                        <a
                          href="#"
                          className="btn primary-btn mx-2"
                          onClick={handleSubmit}
                        >
                          Save update
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/*.page-body*/}
            </div>
            {/*.col-grid*/}
          </div>
          {/*.row*/}
        </div>
        {/*.container-fluid*/}
      </main>
    </>
  );
}

export default ClientProfile;
