import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FileCtx } from "../../context/File";
import setAuthToken from "../../utils/setAuthToken";
import axios from "axios";
import {
  BACKEND_URL,
  CONFIRM_COMPLETE_API_URL,
  DELETE_FILE_API_URL,
  UPLOAD_API_URL,
} from "../../config/endPoints";
import { Popconfirm, Progress, Tooltip, message } from "antd";
import {
  formatDateTime,
  getFileIcon,
  truncateFileName,
  truncateString,
} from "../../utils/helpers";
import { UtilsCtx } from "../../context/UtilsContext";
import ClientSidebar from "../../components/layout/client/ClientSidebar";
import ClientHeader from "../../components/layout/client/ClientHeader";
import { Helmet } from "react-helmet";

function InnerFolder() {
  const token = localStorage.getItem("token");

  const {
    getInnerFiles,
    innerFiles,
    sFileId,
    pName,
    setSFileId,
    setPName,
    setPStatus,
    setPId,
    pStatus,
    pId,
  } = useContext(FileCtx);
  const { handleDownloadAttachment, isSidebarOpen, downloadMultipleFiles } =
    useContext(UtilsCtx);

  const MAX_ATTACHMENTS = 5;
  const MAX_FILE_SIZE_MB = 50;
  const chunkSize = 500 * 1024;

  const [requirementsFiles, setRequirementsFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  useEffect(() => {
    if (token) {
      setAuthToken(token);
      getInnerFiles(token);
    }
  }, [sFileId, pStatus, pId, pName]);

  const resetSelection = () => {
    setCurrentFileIndex(null);
    setLastUploadedFileIndex(null);
    setCurrentChunkIndex(null);
    setUploadedAttachments([]);
    setRequirementsFiles([]);
    setUploadComplete(false);
    getInnerFiles(token);
  };

  const handleAttachmentsChange = (e) => {
    const files = e.target.files;
    if (files.length > MAX_ATTACHMENTS) {
      message.error(`You can only attach up to ${MAX_ATTACHMENTS} files.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`File size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    setRequirementsFiles(files);
  };

  function readNUploadCurrentChunk() {
    const reader = new FileReader();
    const file = requirementsFiles[currentFileIndex];
    if (!file) {
      return;
    }
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadAChunk(e);
    reader.readAsDataURL(blob);
  }

  function uploadAChunk(readerEvent) {
    const file = requirementsFiles[currentFileIndex];
    const data = readerEvent.target.result;
    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set("size", file.size);
    params.set("parentId", sFileId);
    params.set("currentChunkIndex", currentChunkIndex);
    params.set("totalChunks", Math.ceil(file.size / chunkSize));
    params.set("fileType", "attachments");
    const headers = { "Content-Type": "application/octet-stream" };
    const url = `${UPLOAD_API_URL}?` + params.toString();
    axios.post(url, data, { headers }).then((response) => {
      const file = requirementsFiles[currentFileIndex];
      const filesize = requirementsFiles[currentFileIndex].size;
      const chunks = Math.ceil(filesize / chunkSize) - 1;
      const isLastChunk = currentChunkIndex === chunks;
      if (isLastChunk) {
        file.finalFilename = response.data.finalFilename;

        setLastUploadedFileIndex(currentFileIndex);
        setCurrentChunkIndex(null);
        setUploadedAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            filename: file.name,
            filePath: response.data,
            filesize: filesize,
          },
        ]);
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1);
      }
    });
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return;
    }
    const isLastFile = lastUploadedFileIndex === requirementsFiles.length - 1;
    if (isLastFile) {
      setUploadComplete(true);
    }
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1;
    setCurrentFileIndex(nextFileIndex);
  }, [lastUploadedFileIndex]);

  useEffect(() => {
    if (requirementsFiles.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        );
      }
    }
  }, [requirementsFiles.length]);

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readNUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  useEffect(() => {
    if (requirementsFiles?.length > 0 && currentFileIndex !== null) {
      setUploading(true);
    } else {
      setUploading(false);
    }
  }, [requirementsFiles, currentFileIndex, uploadComplete]);

  useEffect(() => {
    if (uploadComplete) {
      resetSelection();
    }
  }, [uploadComplete]);

  const uploadProgress =
    requirementsFiles?.length > 0 && currentFileIndex !== null ? (
      <div style={{ textAlign: "center" }}>
        <p style={{ marginBottom: "5px" }}>{`${currentFileIndex + 1}/${
          requirementsFiles?.length
        }`}</p>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "5px",
          }}
        >
          <Progress
            type="circle"
            percent={Math.round(
              (currentChunkIndex /
                Math.ceil(
                  requirementsFiles[currentFileIndex]?.size / chunkSize
                )) *
                100
            )}
            status="active"
            width={40}
            strokeWidth={10}
          />
        </div>
      </div>
    ) : null;

  const confirmDFile = (id) => {
    axios
      .delete(`${DELETE_FILE_API_URL}/${id}`)
      .then(() => {
        getInnerFiles(token);
        message.success(`File deleted successfully.`);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const confirmComplete = (id) => {
    axios
      .put(`${CONFIRM_COMPLETE_API_URL}/${id}`)
      .then(() => {
        getInnerFiles(token);
        setPStatus(true);
        message.success(`Folder marked as complete successfully.`);
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const sFileIdHandler = (oid, n, ps, pi) => {
    setSFileId(oid);
    localStorage.setItem("sFileId", oid);
    setPName(n);
    localStorage.setItem("pName", n);
    setPStatus(ps);
    localStorage.setItem("pStatus", ps);
    setPId(pi);
    localStorage.setItem("pId", pi);
  };

  const setFId = () => {
    setSFileId(pId);
    localStorage.setItem("sFileId", pId);
  };

  return (
    <>
      <Helmet>
        <title>AGA Tax Client Portal</title>
        <meta name="" content="" />
      </Helmet>

      <link rel="stylesheet" href="./css/main.css" />
      {/*=============== Start Dashboard  ===============*/}
      <section
        className={
          isSidebarOpen
            ? "dashboard-wrapper"
            : "dashboard-wrapper sidebar-toggle"
        }
      >
        <ClientSidebar />
        {/*.aside*/}
        <ClientHeader />
        {/*.header*/}
        <main className="page-content shadow-sm d-flex flex-column justify-content-between">
          <div className="container-fluid p-3 p-md-4">
            <div className="row">
              <div className="col-12">
                <div className="page-header position-relative d-flex flex-column flex-sm-row align-items-md-center justify-content-between gap-3 mb-4">
                  {/* <div className="page-navigator d-flex gap-2">
                    {pId && pId !== sFileId ? (
                      <button className="btn p-0 navi-prev" onClick={setFId}>
                        <i className="bi bi-arrow-left-circle-fill" />
                      </button>
                    ) : (
                      <button className="btn p-0 navi-prev" disabled>
                        <i className="bi bi-arrow-left-circle-fill" />
                      </button>
                    )}

                    <button className="btn p-0 navi-next" disabled>
                      <i className="bi bi-arrow-right-circle-fill" />
                    </button>
                  </div> */}

                  <div className="breadcrumb-wrapper mb-3 d-flex flex-wrap align-items-center gap-3">
                    <div
                      aria-label="breadcrumb"
                      style={{ "--bs-breadcrumb-divider": '">"' }}
                    >
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item fs-4">
                          <Link to="/my-drive">My Drive</Link>
                        </li>
                        <li
                          className="breadcrumb-item active fs-4"
                          aria-current="page"
                        >
                          {innerFiles[0]?.parentDetails[0]?.name
                            ? innerFiles[0]?.parentDetails[0]?.name
                            : pName}
                        </li>
                      </ol>
                    </div>
                    {uploading ? (
                      uploadProgress
                    ) : pStatus === false ? (
                      <>
                        <div className="add-inner-wrapper">
                          <div className="file-attachment">
                            <button className="btn btn-add-new rounded-pill shadow-sm">
                              <i className="bi bi-plus-lg" />
                              <Tooltip
                                title="Max 50MB/File"
                                mouseEnterDelay={0.1}
                              >
                                <input
                                  type="file"
                                  name="file"
                                  multiple
                                  onChange={handleAttachmentsChange}
                                />
                              </Tooltip>{" "}
                            </button>
                          </div>
                        </div>
                        <div
                          aria-label="breadcrumb"
                          style={{ "--bs-breadcrumb-divider": '">"' }}
                        >
                          <ol className="breadcrumb m-0">
                            <li
                              className="breadcrumb-item fs-6"
                              style={{ cursor: "pointer" }}
                            >
                              <Popconfirm
                                title="Confirm Complete"
                                description={`Marking folder complete disables further file uploads. Confirm this action?`}
                                onConfirm={() => confirmComplete(sFileId)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                  class="bi bi-check-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                </svg>{" "}
                                Mark Complete
                              </Popconfirm>
                            </li>
                          </ol>
                        </div>
                      </>
                    ) : null}
                  </div>
                  {/*breadcrumb-wrapper*/}
                  <div className="page-header-right"></div>
                </div>
                {/*.page-header*/}
                <div className="page-body position-relative mb-4">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="accordion drive-accordion"
                        id="accordionPanelsStayOpenExample"
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="panelsFolders">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseOne"
                            >
                              <span>
                                {innerFiles?.filter((f) => f.type === "folder")
                                  .length > 1
                                  ? "Folders"
                                  : "Folder"}
                              </span>
                              <span>
                                (
                                {
                                  innerFiles?.filter((f) => f.type === "folder")
                                    .length
                                }
                                )
                              </span>
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsFolders"
                          >
                            <div className="accordion-body">
                              <div className="row g-3">
                                {innerFiles
                                  ?.filter((f) => f.type === "folder")
                                  .map((f, i) => (
                                    <div className="col-md-4 col-sm-6" key={i}>
                                      <div className="listing-item">
                                        <div className="icon-wrapper">
                                          <span className="icon">
                                            <i className="bi bi-folder-fill" />
                                          </span>
                                        </div>
                                        <div className="item-text">
                                          <Link
                                            to="/inner-folder"
                                            style={{
                                              textDecoration: "none",
                                              color: "#212529",
                                            }}
                                            onClick={() =>
                                              sFileIdHandler(
                                                f._id,
                                                f?.name,
                                                f?.isLocked,
                                                f?.parent
                                              )
                                            }
                                          >
                                            <span>
                                              {truncateString(f?.name, 6)}
                                            </span>
                                          </Link>
                                        </div>
                                        <div className="item-action dropdown">
                                          <a
                                            href="#"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="bi bi-three-dots-vertical" />
                                          </a>
                                          <ul className="dropdown-menu dropdown-menu-end border py-0 shadow-sm">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={() =>
                                                  downloadMultipleFiles(f?._id)
                                                }
                                              >
                                                <i className="bi bi-download" />{" "}
                                                Download Files
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      {/*.listing-item*/}
                                    </div>
                                  ))}

                                {/*.col-grid*/}
                              </div>
                              {/*..row*/}
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="panelsFiles">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTwo"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwo"
                            >
                              <span>
                                {innerFiles?.filter((f) => f.type === "file")
                                  .length > 1
                                  ? "Files"
                                  : "File"}
                              </span>
                              <span>
                                (
                                {
                                  innerFiles?.filter((f) => f.type === "file")
                                    .length
                                }
                                )
                              </span>
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsFiles"
                          >
                            <div className="accordion-body">
                              <div className="row g-3">
                                {innerFiles
                                  ?.filter((f) => f.type === "file")
                                  .map((f, i) => (
                                    <div className="col-md-4 col-sm-6" key={i}>
                                      <div className="listing-item">
                                        <div className="icon-wrapper">
                                          <span className="icon">
                                            <i
                                              className={getFileIcon(
                                                `${BACKEND_URL}/${f?.filePath}`
                                              )}
                                            />
                                          </span>
                                        </div>
                                        <div className="item-text">
                                          <span>
                                            {truncateFileName(f?.filename, 27)}
                                            {` - ${formatDateTime(
                                              f?.createdAt
                                            )}`}
                                          </span>
                                        </div>
                                        <div className="item-action dropdown">
                                          <a
                                            href="#"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="bi bi-three-dots-vertical" />
                                          </a>
                                          <ul className="dropdown-menu dropdown-menu-end border py-0 shadow-sm">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                href="#"
                                                onClick={() =>
                                                  handleDownloadAttachment(
                                                    f?._id,
                                                    f?.filename,
                                                    f?.filePath
                                                  )
                                                }
                                              >
                                                <i className="bi bi-download" />{" "}
                                                Download
                                              </a>
                                            </li>

                                            {!pStatus && (
                                              <li>
                                                <Popconfirm
                                                  title="Confirm Delete"
                                                  description={`Are you sure you want to delete this file?`}
                                                  onConfirm={() =>
                                                    confirmDFile(f?._id)
                                                  }
                                                  okText="Yes"
                                                  cancelText="No"
                                                >
                                                  <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    id="deleteToastBtn"
                                                  >
                                                    <i className="bi bi-trash" />{" "}
                                                    Delete
                                                  </a>
                                                </Popconfirm>
                                              </li>
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                      {/*.listing-item*/}
                                    </div>
                                  ))}

                                {/*.col-grid*/}
                              </div>
                              {/*..row*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*.page-body*/}
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.container-fluid*/}
        </main>
        {/*.page-content"*/}
      </section>

      {/*=============== End Dashboard ===============*/}
    </>
  );
}

export default InnerFolder;
