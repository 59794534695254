import React, { useContext, useEffect, useState } from "react";
import AdminSidebar from "../../components/layout/admin/AdminSidebar";
import AdminHeader from "../../components/layout/admin/AdminHeader";
import { AuthContext } from "../../context/Auth";
import { SearchCtx } from "../../context/SearchContext";
import axios from "axios";
import { DELETE_ADMIN_USER_API_URL } from "../../config/endPoints";
import { Popconfirm, message } from "antd";
import { constructImageUrl } from "../../utils/helpers";
import MUASearch from "../../components/layout/admin/manageUsers/MUASearch";
import { useNavigate } from "react-router-dom";
import { UtilsCtx } from "../../context/UtilsContext";
import { Helmet } from "react-helmet";

function ManageUsers() {
  const token = localStorage.getItem("token");

  const { userInfo, getAdminUsers, adminUsers, updateAUser, loading } =
    useContext(AuthContext);
  const { isSidebarOpen } = useContext(UtilsCtx);

  const { searchOrder } = useContext(SearchCtx);

  const [selectedUInfo, setSelectedUInfo] = useState({});
  const [updateAltUser, setUpdateAltUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    status: "",
    password: "",
    image: [],
  });

  const sUid = selectedUInfo?._id;

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUpdateAltUser({ ...updateAltUser, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateAUser(updateAltUser, sUid);
  };

  const confirm = (id) => {
    axios
      .delete(`${DELETE_ADMIN_USER_API_URL}/${id}`)
      .then(() => {
        getAdminUsers(token);
        message.success("Client deleted successfully.");
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
      });
  };

  const MAX_ATTACHMENTS = 1;
  const MAX_FILE_SIZE_MB = 2;

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file?.length > MAX_ATTACHMENTS) {
      message.error(`You can upload only ${MAX_ATTACHMENTS} image.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < file?.length; i++) {
      if (file[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`Image size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    const fileName = file?.name;
    const ext = fileName
      ?.substring(fileName?.lastIndexOf(".") + 1)
      .toLowerCase();

    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "JPEG",
      "JPG",
      "PNG",
      "GIF",
    ];

    if (!allowedExtensions.includes(ext)) {
      message.error("Only image files (JPEG, JPG, PNG, GIF) are allowed.");
      e.target.value = null;
      return;
    }

    setUpdateAltUser((prev) => ({ ...prev, image: [file] }));
  };

  const filteredUsers = adminUsers?.filter(
    (user) =>
      user?.firstName?.toLowerCase().includes(searchOrder.toLowerCase()) ||
      user?.lastName?.toLowerCase().includes(searchOrder.toLowerCase()) ||
      user?.email?.toLowerCase().includes(searchOrder.toLowerCase())
  );

  useEffect(() => {
    setUpdateAltUser({
      email: selectedUInfo?.email,
      userType: selectedUInfo?.userType,
      firstName: selectedUInfo?.firstName,
      lastName: selectedUInfo?.lastName,
      status: selectedUInfo?.status,
      department: selectedUInfo?.department,
      password: "",
    });
  }, [selectedUInfo]);

  useEffect(() => {
    if (token) {
      getAdminUsers(token);
    }
  }, []);

  const navigate = useNavigate();

  if (userInfo?.userType === "client") {
    navigate("/dashboard");
  }

  useEffect(() => {
    document.body.className = "admin-portal";

    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Manage Clients - AGA Tax Client Portal</title>
        <meta name="" content="" />
    </Helmet>

      <link rel="stylesheet" href="../css/main.css" />
      <body className="admin-portal">
        {/*=============== Start Dashboard  ===============*/}
        <section
          className={
            isSidebarOpen
              ? "dashboard-wrapper"
              : "dashboard-wrapper sidebar-toggle"
          }
        >
          <AdminSidebar />
          {/*.aside*/}
          <AdminHeader />
          {/*.header*/}
          <main className="page-content shadow-sm d-flex flex-column justify-content-between">
            <div className="container-fluid p-3 p-md-4">
              <div className="row">
                <div className="col-12">
                  <div className="page-header d-flex flex-column flex-sm-row align-items-md-center justify-content-between gap-3 mb-4">
                    <div className="breadcrumb-wrapper">
                      <h3 className="fs-4">Manage Users</h3>
                    </div>
                    {/*breadcrumb-wrapper*/}
                    <div className="page-header-right"></div>
                  </div>
                  {/*.page-header*/}
                  <div className="page-body position-relative mb-4">
                    <div className="row">
                      <div className="col-12">
                        <div className="page-card position-relative">
                          <MUASearch />
                          {/*.card-header*/}
                          <div className="card-body pt-4">
                            <div className="table-responsive">
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col">Image</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredUsers?.map((ad, i) => (
                                    <tr key={i}>
                                      <td>
                                        {ad?.imageUrl ? (
                                          <img
                                            className="table-img"
                                            src={constructImageUrl(
                                              ad?.imageUrl
                                            )}
                                          />
                                        ) : (
                                          <div className="user-avator-order secondary-bg rounded-circle d-flex align-items-center justify-content-center text-uppercase fw-semibold me-2">
                                            <span className="name-txt">
                                              {`${
                                                ad?.firstName
                                                  ? ad.firstName
                                                      .charAt(0)
                                                      .toUpperCase()
                                                  : ""
                                              }${
                                                ad?.lastName
                                                  ? ad.lastName
                                                      .charAt(0)
                                                      .toUpperCase()
                                                  : ""
                                              }`}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                      <td>{`${ad?.firstName} ${ad?.lastName}`}</td>
                                      <td>{ad?.email}</td>

                                      <td>
                                        <span
                                          className={`d-inline-block badge ${
                                            ad?.status === "inactive"
                                              ? "bg-danger"
                                              : "green-col"
                                          }`}
                                        >
                                          {ad?.status.charAt(0).toUpperCase() +
                                            ad?.status.slice(1)}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          className="tb-action edit"
                                          data-bs-toggle="modal"
                                          data-bs-target="#editUserModal"
                                          onClick={() => setSelectedUInfo(ad)}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={16}
                                            height={16}
                                            fill="currentColor"
                                            className="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                          </svg>
                                        </button>
                                        <Popconfirm
                                          title="Confirm Delete"
                                          description="Are you sure you want to delete this client?"
                                          onConfirm={() => confirm(ad?._id)}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <button className="tb-action delete">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              className="bi bi-trash3"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                          </button>
                                        </Popconfirm>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div
                                className="modal fade"
                                id="editUserModal"
                                tabIndex={-1}
                                aria-labelledby="editUserModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                                  <div className="modal-content">
                                    <div className="modal-header dark-bg px-4">
                                      <h1
                                        className="modal-title fs-5 text-white"
                                        id="cardPaymentModalLabel"
                                      >
                                        Edit Client
                                      </h1>
                                      <button
                                        type="button"
                                        className="btn-close btn-close-white"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      />
                                    </div>
                                    <div className="modal-body px-4">
                                      <form className="needs-validation">
                                        <div className="row g-3">
                                          <div className="col-sm-12">
                                            <label
                                              htmlFor="inputFile01"
                                              className="form-label small fw-semibold"
                                            >
                                              Upload image
                                            </label>
                                            <input
                                              type="file"
                                              className="upload-file-input w-100"
                                              id="image"
                                              onChange={handleImageChange}
                                            />
                                          </div>
                                          <div className="col-sm-6">
                                            <label
                                              htmlFor="firstName"
                                              className="form-label small fw-semibold"
                                            >
                                              First Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="firstName"
                                              placeholder
                                              value={updateAltUser.firstName}
                                              onChange={handleChange}
                                            />
                                          </div>
                                          <div className="col-sm-6">
                                            <label
                                              htmlFor="lastName"
                                              className="form-label small fw-semibold"
                                            >
                                              Last Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="lastName"
                                              placeholder
                                              value={updateAltUser.lastName}
                                              onChange={handleChange}
                                            />
                                          </div>
                                          <div className="col-sm-6">
                                            <label
                                              htmlFor="email"
                                              className="form-label small fw-semibold"
                                            >
                                              Email
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="email"
                                              placeholder
                                              value={updateAltUser.email}
                                              onChange={handleChange}
                                            />
                                          </div>
                                          <div className="col-sm-6">
                                            <label
                                              htmlFor="email"
                                              className="form-label small fw-semibold"
                                            >
                                              Password
                                            </label>
                                            <input
                                              type="password"
                                              className="form-control"
                                              id="password"
                                              placeholder
                                              value={updateAltUser.password}
                                              onChange={handleChange}
                                            />
                                          </div>

                                          <div className="col-sm-6">
                                            <label
                                              htmlFor="status"
                                              className="form-label small fw-semibold"
                                            >
                                              Status
                                            </label>
                                            <select
                                              className="form-select"
                                              id="status"
                                              required
                                              value={updateAltUser.status}
                                              onChange={handleChange}
                                            >
                                              <option value="">
                                                Please Select
                                              </option>
                                              <option value="active">
                                                Active
                                              </option>
                                              <option value="inactive">
                                                Inactive
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                        {/*.row*/}
                                        {/* / Change address form*/}
                                        <div className="btn-wrapper text-end mb-3 mt-4 mt-lg-5">
                                          <a
                                            href="#"
                                            className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            Cancel
                                          </a>
                                          <button
                                            onClick={handleSubmit}
                                            disabled={loading}
                                            className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                                          >
                                            {loading && (
                                              <span className="spinner-grow spinner-grow-sm me-2"></span>
                                            )}
                                            {!loading
                                              ? "Update Client"
                                              : "Updating Client"}
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*.edit-user-modal*/}
                            </div>
                            {/*.table-responsive*/}
                          </div>
                          {/*.card-body*/}
                        </div>
                        {/*.page-card*/}
                      </div>
                      {/*.col-grid*/}
                    </div>
                  </div>
                  {/*.page-body*/}
                </div>
                {/*.col-grid*/}
              </div>
              {/*.row*/}
            </div>
            {/*.container-fluid*/}
          </main>
          {/*.page-content"*/}
        </section>
        {/* Modal Rename */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Rename
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control"
                  defaultValue="Development Data"
                />
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn gray-btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="button" className="btn primary-btn px-4">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          <div
            id="liveToast"
            className="toast shadow-sm p-1"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header border-0 d-flex align-items-center gap-2">
              <span className="icon">
                <i className="bi bi-trash3-fill" />
              </span>
              <strong className="me-auto fw-semibold">
                Develompent folder deleted
              </strong>
            </div>
          </div>
        </div>
      </body>

      {/*delete toast*/}
      {/*=============== End Dashboard ===============*/}
    </>
  );
}

export default ManageUsers;
