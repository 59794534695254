import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationContext } from "./context/Auth";
import { SearchContext } from "./context/SearchContext";
import { FileContext } from "./context/File";
import { UtilsContext } from "./context/UtilsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthenticationContext>
      <SearchContext>
        <FileContext>
          <UtilsContext>
            <App />
          </UtilsContext>
        </FileContext>
      </SearchContext>
    </AuthenticationContext>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
