import React, { useContext, useEffect } from "react";
import AdminSidebar from "../../components/layout/admin/AdminSidebar";
import AdminHeader from "../../components/layout/admin/AdminHeader";
import ClientProfile from "../../components/layout/client/profile/ClientProfile";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth";
import { UtilsCtx } from "../../context/UtilsContext";
import { Helmet } from "react-helmet";

function Admin() {
  const { userInfo } = useContext(AuthContext);
  const { isSidebarOpen } = useContext(UtilsCtx);

  const navigate = useNavigate();

  useEffect(() => {
    document.body.className = "admin-portal";

    return () => {
      document.body.className = "";
    };
  }, []);

  if (userInfo?.userType === "client") {
    navigate("/profile");
  }

  return (
    <>
      <Helmet>
        <title>Admin Profile - AGA Tax Client Portal</title>
        <meta name="" content="" />
    </Helmet>

      <link rel="stylesheet" href="./css/main.css" />
      <body className="admin-portal">
        {/*=============== Start Dashboard  ===============*/}
        <section
          className={
            isSidebarOpen
              ? "dashboard-wrapper"
              : "dashboard-wrapper sidebar-toggle"
          }
        >
          <AdminSidebar />
          {/*.aside*/}
          <AdminHeader />
          {/*.header*/}
          <ClientProfile />
          {/*.page-content"*/}
        </section>
        {/* Modal Rename */}
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">
                  Rename
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control"
                  defaultValue="Development Data"
                />
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn gray-btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button type="button" className="btn primary-btn px-4">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          <div
            id="liveToast"
            className="toast shadow-sm p-1"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header border-0 d-flex align-items-center gap-2">
              <span className="icon">
                <i className="bi bi-trash3-fill" />
              </span>
              <strong className="me-auto fw-semibold">
                Develompent folder deleted
              </strong>
            </div>
          </div>
        </div>
      </body>
      {/*delete toast*/}
      {/*=============== End Dashboard ===============*/}
    </>
  );
}

export default Admin;
