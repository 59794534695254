import React, { useContext, useEffect } from "react";

import { message } from "antd";
import { AuthContext } from "../../../../context/Auth";
import { SearchCtx } from "../../../../context/SearchContext";

function MUASearch() {
  const { addAdminUser, loading, userInfo, adminUser, setAdminUser } =
    useContext(AuthContext);

  const { searchOrder, setSearchOrder } = useContext(SearchCtx);

  const handleSearchChange = (e) => {
    setSearchOrder(e.target.value);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAdminUser({ ...adminUser, [id]: value });
  };

  const MAX_ATTACHMENTS = 1;
  const MAX_FILE_SIZE_MB = 2;

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file?.length > MAX_ATTACHMENTS) {
      message.error(`You can upload only ${MAX_ATTACHMENTS} image.`);
      e.target.value = null;
      return;
    }

    for (let i = 0; i < file?.length; i++) {
      if (file[i].size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        message.error(`Image size should not exceed ${MAX_FILE_SIZE_MB} MB.`);
        e.target.value = null;
        return;
      }
    }

    const fileName = file?.name;
    const ext = fileName
      ?.substring(fileName?.lastIndexOf(".") + 1)
      .toLowerCase();

    const allowedExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "JPEG",
      "JPG",
      "PNG",
      "GIF",
    ];

    if (!allowedExtensions.includes(ext)) {
      message.error("Only image files (JPEG, JPG, PNG, GIF) are allowed.");
      e.target.value = null;
      return;
    }

    setAdminUser((prev) => ({ ...prev, image: [file] }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addAdminUser(adminUser);
  };

  useEffect(() => {
    setAdminUser((prevData) => ({ ...prevData, userId: userInfo?._id }));
  }, [userInfo]);

  return (
    <>
      <div className="card-header p-3 py-2 bg-light">
        <div className="row">
          <div className="col-md-11 col-lg-10 col-xl-8">
            <div className="search-wrapper d-flex flex-column flex-sm-row align-items-sm-center">
              <label className="h6 fw-semibold me-2 mb-0" htmlFor="searh-input">
                Search users
              </label>
              <form
                className="search-form position-relative flex-fill mt-sm-0 mt-2"
                action="#"
              >
                <input
                  className="form-control w-100 rounded-pill ps-3"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  value={searchOrder}
                  onChange={handleSearchChange}
                />
                <span className="search-box-icon position-absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={14}
                    height={14}
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </span>
              </form>
              <button
                className="btn primary-btn rounded-pill fs-6 mt-sm-0 mt-2 ms-sm-2 px-3 py-2 justify-content-sm-start justify-content-center d-inline-flex align-items-center"
                data-bs-toggle="modal"
                data-bs-target="#inviteUserModal"
              >
                <span className="icon me-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={14}
                    height={14}
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      strokeWidth=".5"
                      stroke="currentColor"
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                    />
                  </svg>
                </span>
                Add Client
              </button>
              {/* invite-user-modal */}
              <div
                className="modal fade"
                id="inviteUserModal"
                tabIndex={-1}
                aria-labelledby="inviteUserModalLabel"
                aria-hidden="true"
                style={{ display: "none" }}
              >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header dark-bg px-4">
                      <h1
                        className="modal-title fs-5 text-white"
                        id="cardPaymentModalLabel"
                      >
                        Add New Client
                      </h1>
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body px-4 py-4">
                      <form className="needs-validation">
                        <div className="row g-2">
                          <div className="col-sm-12">
                            <label
                              htmlFor="inputFile01"
                              className="form-label small fw-semibold"
                            >
                              Upload image
                            </label>
                            <input
                              type="file"
                              className="upload-file-input w-100"
                              id="image"
                              onChange={handleImageChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="firstName"
                              className="form-label small fw-semibold"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstName"
                              placeholder
                              value={adminUser.firstName}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="lastName"
                              className="form-label small fw-semibold"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              placeholder
                              value={adminUser.lastName}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="email"
                              className="form-label small fw-semibold"
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder
                              value={adminUser.email}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label
                              htmlFor="email"
                              className="form-label small fw-semibold"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              placeholder
                              value={adminUser.password}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/*.row*/}
                        {/* / Change address form*/}
                      </form>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-wrapper text-end mb-3">
                        <a
                          href="#"
                          className="fs-6 btn btn-light me-2 px-4 py-2 text-decoration-none"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </a>
                        <button
                          onClick={handleSubmit}
                          disabled={loading}
                          className="btn primary-btn fw-semibold fs-6 px-4 py-2"
                        >
                          {loading && (
                            <span className="spinner-grow spinner-grow-sm me-2"></span>
                          )}
                          {!loading ? "Add Client" : "Adding Client"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*.invite-user-modal*/}
            </div>
            {/*.search-wrapper*/}
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </div>
    </>
  );
}

export default MUASearch;
